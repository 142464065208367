import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import Text from '@/style/components/LegacyText'
import { hideFrom, hideTo } from '@/style/helpers/media'

interface ConfiguratorHeadlineProps {
  image: JSX.Element
  categoryHeadline: string
  productHeadline: string
  productSubhead?: string
}

const ConfiguratorHeadline = ({
  image,
  categoryHeadline,
  productHeadline,
  productSubhead,
}: ConfiguratorHeadlineProps) => {
  const renderDesktopLayout = () => (
    <DesktopWrapper>
      <Col xs={10} md={4}>
        <DesktopImageWrapper>{image}</DesktopImageWrapper>
      </Col>
      <Col xs={12} md={7}>
        <h2 className="text-heading-xs font-semibold text-tertiary uppercase">
          {categoryHeadline}
        </h2>
        <Text.xl weight="semibold">
          <Text.modify className="text-primary">{productHeadline}</Text.modify>
          <br />
          {productSubhead}
        </Text.xl>
      </Col>
    </DesktopWrapper>
  )

  const renderMobileLayout = () => (
    <MobileWrapper>
      <h2 className="text-heading-xs font-semibold text-tertiary uppercase">
        {categoryHeadline}
      </h2>
      <MobileImageWrapper>{image}</MobileImageWrapper>
      <Text.xxl weight="semibold">
        <Text.modify className="text-primary">{productHeadline}</Text.modify>
        <br />
        {productSubhead}
      </Text.xxl>
    </MobileWrapper>
  )

  return (
    <>
      {renderDesktopLayout()}
      {renderMobileLayout()}
    </>
  )
}

const DesktopWrapper = styled(Row)`
  align-items: center;

  ${hideTo('md')}

  h2 {
    margin-bottom: 0.5rem;
  }
`

const MobileWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${hideFrom('md')}

  > *:not(:last-child) {
    z-index: 1;
    margin-bottom: 1.5rem;
  }
`

const DesktopImageWrapper = styled.div`
  position: relative;
  width: 80%;
  aspect-ratio: 1;
`

const MobileImageWrapper = styled.div`
  position: relative;
  width: 50vw;
  max-width: 15rem;
  aspect-ratio: 1;
`

export default ConfiguratorHeadline
