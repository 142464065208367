import { MOBILE_SUB_HEADER_PATHS } from '../Header/MobileSubHeader'
import WhatsappBubble from '../WhatsappBubble/WhatsappBubble'
import { useRouter } from 'next/router'
import { createContext, useContext, useState } from 'react'
import { cn } from '@/utils/cn'
import Footer from './Footer'
import Header from './Header'

interface LayoutProps {
  children: React.ReactNode
  fillViewport?: boolean
}

const LayoutContext = createContext<{
  setWhatsappBubbleExtraOffsetBottom: (value: number) => void
}>({
  setWhatsappBubbleExtraOffsetBottom: () => {},
})

const Layout = ({ children, fillViewport = false }: LayoutProps) => {
  const router = useRouter()
  const isCheckout = router.asPath.startsWith('/checkout')
  const isFinalizeAccountSetup = router.asPath.includes(
    '/finalize-account-setup',
  )

  const shouldRenderSubHeader = MOBILE_SUB_HEADER_PATHS.includes(router.asPath)

  const [whatsappBubbleExtraOffsetBottom, setWhatsappBubbleExtraOffsetBottom] =
    useState(0)

  return (
    <LayoutContext.Provider
      value={{
        setWhatsappBubbleExtraOffsetBottom,
      }}
    >
      <div
        className={cn(
          'flex flex-col min-h-screen ',
          '[--primary-header-height:66px] [--header-height:calc(var(--primary-header-height)+var(--sub-header-height))]',
          shouldRenderSubHeader
            ? // We need to set the `sub-header-height` variable depending on whether the subheader should be rendered based on
              // the route, and based on breakpoints. For example, we need to set the height to 0px in e.g. profile pages, and we need to set it to
              // 0px on non-mobile devices. The former is being done here, the latter is being done in the `Header` component. To get a feel
              // as to why this is needed in the first place, check the usages of the `--header-height` variable.
              '[--sub-header-height:48px] md:[--sub-header-height:0px]'
            : '[--sub-header-height:0px]',
        )}
      >
        <Header
          shouldRenderSubHeader={shouldRenderSubHeader}
          shouldRenderCheckoutHeader={isCheckout || isFinalizeAccountSetup}
        />
        <div
          className={cn(
            'flex flex-col flex-1',
            fillViewport && 'md:min-h-[calc(100vh-var(--header-height))]',
          )}
        >
          {children}
        </div>
        <WhatsappBubble extraOffsetBottom={whatsappBubbleExtraOffsetBottom} />
        <Footer />
        <div style={{ height: whatsappBubbleExtraOffsetBottom }} />
      </div>
    </LayoutContext.Provider>
  )
}

export function useLayoutContext() {
  return useContext(LayoutContext)
}

export default Layout
