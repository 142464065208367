import { useStoryblokConfig } from '../../context/storyblokConfigContext'
import { DesktopPrimaryHeader } from '../Header/DesktopPrimaryHeader'
import { useContext, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useLogoutCustomer } from '@/app/auth/auth.service'
import { customerState } from '@/app/auth/auth.state'
import Login from '@/app/auth/components/Login'
import { HeaderLayout } from '@/app/common/components/Header/HeaderLayout'
import { MobilePrimaryHeader } from '@/app/common/components/Header/MobilePrimaryHeader'
import { MobileSubHeader } from '@/app/common/components/Header/MobileSubHeader'
import {
  PrimaryHeaderCheckout,
  TabletPrimaryHeader,
} from '@/app/common/components/Header/TabletPrimaryHeader'
import { Menu } from '@/app/common/components/Layout/Menu/Menu'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { useRecoilStateWithSSRFallback } from '@/app/common/recoil'
import { dealCalculationState } from '@/domains/checkout/checkout.state'

interface Props {
  shouldRenderCheckoutHeader: boolean
  shouldRenderSubHeader: boolean
}

export default function Header({
  shouldRenderSubHeader,
  shouldRenderCheckoutHeader,
}: Props) {
  const { config } = useStoryblokConfig()
  const { navigation_bar } = config
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)

  const [customer] = useRecoilStateWithSSRFallback(customerState, undefined)
  const modal = useContext(ModalDialogContext)
  const isLoggedIn = !!customer

  const handleLogoutClick = useLogoutCustomer()

  const handleLoginClick = () => {
    modal.open(<Login />, { variant: 'full' })
  }

  const [dealCalculation] = useRecoilState(dealCalculationState)

  const itemsTotalAmount =
    dealCalculation?.dealValuesEntry.payoutAmount.toFixed(2) as string

  const navMenu = (
    <Menu open={menuOpen} closeMenu={setMenuOpen.bind(null, false)} />
  )

  if (shouldRenderCheckoutHeader) {
    return (
      <HeaderLayout
        primaryHeader={
          <>
            <PrimaryHeaderCheckout
              menuOpen={menuOpen}
              toggleMenu={toggleMenu}
              isLoggedIn={isLoggedIn}
              onLoginClick={handleLoginClick}
              onLogoutClick={handleLogoutClick}
            />
            <MobilePrimaryHeader
              isCheckout
              menuOpen={menuOpen}
              toggleMenu={toggleMenu}
              isLoggedIn={isLoggedIn}
              onLoginClick={handleLoginClick}
            />
          </>
        }
        navMenu={navMenu}
      />
    )
  }

  return (
    <HeaderLayout
      primaryHeader={
        <>
          <TabletPrimaryHeader
            menuOpen={menuOpen}
            toggleMenu={toggleMenu}
            isLoggedIn={isLoggedIn}
            onLoginClick={handleLoginClick}
            onLogoutClick={handleLogoutClick}
          />

          <DesktopPrimaryHeader
            menuOpen={menuOpen}
            toggleMenu={toggleMenu}
            itemsTotalAmount={itemsTotalAmount}
            navigationBar={navigation_bar}
            isLoggedIn={isLoggedIn}
            onLoginClick={handleLoginClick}
            onLogoutClick={handleLogoutClick}
          />

          <MobilePrimaryHeader
            menuOpen={menuOpen}
            toggleMenu={toggleMenu}
            isLoggedIn={isLoggedIn}
            onLoginClick={handleLoginClick}
          />
        </>
      }
      navMenu={navMenu}
      subHeader={shouldRenderSubHeader && <MobileSubHeader />}
    />
  )
}
