import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { pathBookmarkState } from '@/app/common/common.state'

export const useSavePathBookmark = () => {
  const { asPath } = useRouter()
  const setBookmark = useSetRecoilState(pathBookmarkState)

  return useCallback(() => {
    setBookmark(asPath)
  }, [setBookmark, asPath])
}

export const useNavigateToPathBookmark = (fallback: string) => {
  const router = useRouter()
  const [bookmark, setBookmark] = useRecoilState(pathBookmarkState)

  return useCallback(() => {
    const url = bookmark ?? fallback
    if (!url) {
      throw new Error('No path bookmark found')
    }
    setBookmark(undefined)
    router.push(url)
  }, [bookmark, fallback, router, setBookmark])
}
