import * as Collapsible from '@radix-ui/react-collapsible'
import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import StoryblokRichText from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { FaqItemStoryblok } from '@/types/storyblok-component-types'

export default function FAQItem({ blok }: { blok: FaqItemStoryblok }) {
  return (
    <div
      className="bg-fill-senary rounded-xl overflow-hidden"
      {...storyblokEditable(blok)}
    >
      <Collapsible.Root className="w-full">
        <Collapsible.Trigger className="hover:bg-fill-quinary rounded-xl w-full text-left py-5 px-4 flex flex-row items-stretch justify-between gap-6 group">
          <div className="font-semibold text-heading-xs">
            <StoryblokRichText document={blok.question} />
          </div>
          <div className="flex items-center justify-center">
            <div className="transform transition-transform duration-100 group-data-[state=open]:rotate-180 h-6 w-6 text-gray-600 group-hover:text-gray-800">
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
        </Collapsible.Trigger>

        <Collapsible.Content className="data-[state=open]:animate-expand data-[state=closed]:animate-collapse">
          <div className="pb-6 pt-1 px-4">
            <StoryblokRichText document={blok.answer} />
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    </div>
  )
}
