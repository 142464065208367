import StoryblokPicture from '../../app/common/components/StoryblokImage/StoryblokPicture'
import { storyblokEditable } from '@storyblok/react'
import React, { CSSProperties } from 'react'
import config from '@/../tailwind.config'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { HeroCardStoryblok } from '@/types/storyblok-component-types'
import { cn } from '@/utils/cn'

export default function HeroCard({ blok }: { blok: HeroCardStoryblok }) {
  return (
    <div
      className="flex gap-6 justify-center items-center m-4 md:m-12 w-full max-w-[1400px] h-[--mobile-height] md:h-[--desktop-height]"
      {...storyblokEditable(blok)}
      style={
        {
          '--mobile-height': blok.mobile_height + 'px',
          '--desktop-height': blok.desktop_height + 'px',
        } as CSSProperties
      }
    >
      <RichTextParagraphStyles className="flex-1 text-left break-words hidden md:block">
        <StoryblokRichText document={blok.desktop_content} />
      </RichTextParagraphStyles>

      <div className="relative flex-1 h-full rounded-xl overflow-hidden">
        <StoryblokPicture
          sizes={`(min-width: ${config.theme.screens.md}) 50vw, 100vw`}
          className="object-cover object-center size-full"
          images={[
            {
              image: blok.hero_image_mobile,
            },
            {
              image: blok.hero_image_desktop,
              minWidth: config.theme.screens.md,
              scale: 0.5,
            },
          ]}
        />

        <div
          className="absolute inset-0 md:hidden"
          style={
            {
              backgroundImage:
                'linear-gradient(to bottom, var(--gradient-top) 20%, var(--gradient-bottom))',
              '--gradient-bottom':
                blok.mobile_gradient_color_bottom?.color || transparent,
              '--gradient-top':
                blok.mobile_gradient_color_top?.color || transparent,
            } as React.CSSProperties
          }
        />

        <div
          className={cn(
            'absolute inset-0 flex flex-col items-center text-center px-6 py-16 md:hidden',
            mobileAlignmentTw[blok.mobile_content_alignment || 'center'],
          )}
        >
          <RichTextParagraphStyles className="break-words">
            <StoryblokRichText document={blok.mobile_content} />
          </RichTextParagraphStyles>
        </div>
      </div>
    </div>
  )
}

const mobileAlignmentTw: Record<
  Exclude<HeroCardStoryblok['mobile_content_alignment'], ''>,
  string
> = {
  top: 'justify-start',
  center: 'justify-center',
  bottom: 'justify-end',
}

const transparent = `rgba(0, 0, 0, 0)`
