import AccountIcon from '../assets/icon_account.svg'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  GridItem,
} from '@/app/common/components/Header/components'

const headerPrefix = 'header'

interface Props {
  isLoggedIn: boolean
  onLoginClick: () => void
  onLogoutClick: () => void
}

export default function AccountButton({
  isLoggedIn,
  onLoginClick,
  onLogoutClick,
}: Props) {
  const { t } = useTranslation()

  return (
    <GridItem zIndex={1} area="account">
      {isLoggedIn ? (
        <Dropdown className="h-full">
          <Link
            className="h-full w-10 flex justify-center items-center"
            id={`${headerPrefix}_profile`}
            href={'/profile'}
          >
            <AccountIcon className="fill-accent-500 text-accent-500" />
          </Link>

          <DropdownMenu className="-right-3">
            <Link id={`${headerPrefix}_profile`} href={'/profile'}>
              <DropdownItem>{t('common:header.profile')}</DropdownItem>
            </Link>

            <DropdownItem onClick={onLogoutClick}>
              {t('common:auth.logout')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <button
          className="h-full w-10 flex justify-center items-center"
          onClick={onLoginClick}
        >
          <AccountIcon />
        </button>
      )}
    </GridItem>
  )
}
