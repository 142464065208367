/*
    DEFAULT SEO PARAMS - CAN BE OVERWRITTEN ON A PER-PAGE BASIS
    Documentation: https://github.com/garmeeh/next-seo#readme
 */
import { DefaultSeoProps } from 'next-seo'
import config from '@/config'
import { ERegionCode } from '@/types/gql/graphql'

export const regions = {
  [ERegionCode.At]: {
    regionCode: 'at',
    baseUrl: config.rootUrl.austria,
    defaultLocale: 'de',
    locales: ['en'],
  },
  [ERegionCode.De]: {
    regionCode: 'de',
    baseUrl: config.rootUrl.germany,
    defaultLocale: 'de',
    locales: ['en'],
  },
}

export const getDefaultSeoProps = (
  regionCode: ERegionCode,
  path: string,
  locale: string,
): DefaultSeoProps => {
  if (!(regionCode in regions)) {
    throw new Error('Region is invalid for SEO props')
  }

  const noRobots = config.env !== 'production'
  return {
    canonical: getDefaultCanonical(regionCode, path, locale),
    openGraph: {
      type: 'website',
      locale: `${locale}_${regions[regionCode].regionCode.toUpperCase()}`,
      url: new URL(path, regions[regionCode].baseUrl).href,
      site_name: 'CASHY',
      title: defaultSeoTitle,
      images: [
        {
          url: 'https://storage.googleapis.com/cashy-api-v2-content/cashy_pfandkredit_ankauf_303b1af1a8/cashy-pfandkredit-ankauf.png',
          width: 1200,
          height: 630,
          alt: 'Cashy Ankauf Pfandleihe',
        },
      ],
    },
    ...(noRobots
      ? {
          dangerouslySetAllPagesToNoIndex: true,
          dangerouslySetAllPagesToNoFollow: true,
          dangerouslyDisableGooglebot: true,
        }
      : {}),
  }
}

export const getDefaultCanonical = (
  regionCode: ERegionCode,
  path: string,
  locale: string,
) => {
  const regionOpts = regions[regionCode]
  const url = new URL(
    regionOpts.defaultLocale === locale ? path : locale + path,
    regionOpts.baseUrl,
  )

  url.search = ''
  url.hash = ''

  return url.href
}

export const defaultSeoTitle = 'Pfandhaus CASHY'
