import { throttle } from 'lodash'
import { useCallback } from 'react'

/**
 * Use in React Functional Components to make throttle work (otherwise, new callback function instance would be created on each render.)
 *
 * Attention: The callback must be a pure function!
 *
 * @param callback
 * @param delay
 */
export function useThrottledCallback<T extends (...args: any) => any>(
  callback: T,
  delay: number,
  dep?: any[],
) {
  const d = callback

  // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callbackfunc = useCallback(throttle(d, delay), dep ?? [])

  return [callbackfunc]
}
