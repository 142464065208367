const stroke = {
  accent: 'var(--accent-500, rgb(80 185 117))',
  attention: 'var(--orange-500, rgb(237 160 52))',
  brand: 'var(--brand-500, rgb(42 96 183))',
  negative: 'var(--red-500, rgb(212 64 64))',
  positive: 'var(--green-500, rgb(80 185 117))',
  primary: 'var(--neutral-500, rgb(115 115 115))',
  secondary: 'var(--neutral-300, rgb(212 212 212))',
  tertiary: 'var(--neutral-100, rgb(244 244 244))',
}

module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx,mdx}'],
  theme: {
    screens: {
      xs: '480px',
      sm: '768px',
      md: '992px',
      lg: '1200px',
    },

    extend: {
      fontFamily: {
        sans: 'GalanoGrotesque',
      },
      colors: {
        primary: '#002147',
        'cashy-gray': '#999696',
        primaryHover: '#EBEFF5',
        secondary: '#01FF6C',

        // primary
        'accent-100': 'rgb(222 242 229)',
        'accent-200': 'rgb(186 227 200)',
        'accent-300': 'rgb(153 214 174)',
        'accent-400': 'rgb(118 200 147)',
        'accent-50': 'rgb(237 248 241)',
        'accent-500': 'rgb(80 185 117)',
        'accent-600': 'rgb(61 153 93)',
        'accent-700': 'rgb(45 113 69)',
        'accent-800': 'rgb(31 76 47)',
        'accent-900': 'rgb(15 36 22)',
        'accent-950': 'rgb(7 18 11)',
        'brand-100': 'rgb(216 226 238)',
        'brand-200': 'rgb(169 193 224)',
        'brand-300': 'rgb(123 161 214)',
        'brand-400': 'rgb(74 127 207)',
        'brand-50': 'rgb(235 239 245)',
        'brand-500': 'rgb(42 96 183)',
        'brand-600': 'rgb(27 67 136)',
        'brand-700': 'rgb(0 33 71)',
        'brand-800': 'rgb(12 29 60)',
        'brand-900': 'rgb(7 15 29)',
        'brand-950': 'rgb(4 9 16)',
        'green-100': 'rgb(222 242 229)',
        'green-200': 'rgb(186 227 200)',
        'green-300': 'rgb(153 214 174)',
        'green-400': 'rgb(118 200 147)',
        'green-50': 'rgb(237 248 241)',
        'green-500': 'rgb(80 185 117)',
        'green-600': 'rgb(61 153 93)',
        'green-700': 'rgb(45 113 69)',
        'green-800': 'rgb(31 76 47)',
        'green-900': 'rgb(15 36 22)',
        'green-950': 'rgb(7 18 11)',
        'neutral-100': 'rgb(244 244 244)',
        'neutral-200': 'rgb(229 229 229)',
        'neutral-300': 'rgb(212 212 212)',
        'neutral-400': 'rgb(163 163 163)',
        'neutral-50': 'rgb(250 250 250)',
        'neutral-500': 'rgb(115 115 115)',
        'neutral-600': 'rgb(82 82 82)',
        'neutral-700': 'rgb(64 64 64)',
        'neutral-800': 'rgb(38 38 38)',
        'neutral-900': 'rgb(23 23 23)',
        'neutral-950': 'rgb(10 10 10)',
        'orange-100': 'rgb(246 226 197)',
        'orange-200': 'rgb(241 208 162)',
        'orange-300': 'rgb(236 190 126)',
        'orange-400': 'rgb(230 172 91)',
        'orange-50': 'rgb(249 235 215)',
        'orange-500': 'rgb(237 160 52)',
        'orange-600': 'rgb(191 123 29)',
        'orange-700': 'rgb(138 89 21)',
        'orange-800': 'rgb(84 54 13)',
        'orange-900': 'rgb(31 20 5)',
        'orange-950': 'rgb(37 8 8)',
        'primary-black': 'rgb(0 0 0)',
        'primary-black-50': 'rgb(0, 0, 0, 0.5)',
        'primary-white': 'rgb(255 255 255)',
        'primary-white-50': 'rgb(255, 255, 255, 0.5)',
        'red-100': 'rgb(241 193 193)',
        'red-200': 'rgb(234 164 164)',
        'red-300': 'rgb(228 134 134)',
        'red-400': 'rgb(221 105 105)',
        'red-50': 'rgb(244 207 207)',
        'red-500': 'rgb(212 64 64)',
        'red-600': 'rgb(183 42 42)',
        'red-700': 'rgb(141 32 32)',
        'red-800': 'rgb(100 23 23)',
        'red-900': 'rgb(58 13 13)',
        'red-950': 'rgb(37 8 8)',

        // semantic
        'fill-on-accent': 'var(--primary-white, rgb(255 255 255))',
        'fill-on-primary': 'var(--primary-white, rgb(255 255 255))',
        'fill-primary': 'var(--neutral-900, rgb(23 23 23))',
        'fill-quaternary': 'var(--neutral-400, rgb(163 163 163))',
        'fill-quinary': 'var(--neutral-200, rgb(229 229 229))',
        'fill-secondary': 'var(--neutral-700, rgb(64 64 64))',
        'fill-senary': 'var(--neutral-50, rgb(250 250 250))',
        'fill-tertiary': 'var(--neutral-500, rgb(115 115 115))',
        'fill-accent-primary': 'var(--accent-500, rgb(80 185 117))',
        'fill-accent-secondary': 'var(--accent-400, rgb(118 200 147))',
        'fill-accent-tertiary': 'var(--accent-50, rgb(237 248 241))',
        'fill-attention-primary': 'var(--orange-500, rgb(237 160 52))',
        'fill-attention-secondary': 'var(--orange-300, rgb(236 190 126))',
        'fill-attention-tertiary': 'var(--orange-50, rgb(249 235 215))',
        'fill-brand-primary': 'var(--brand-700, rgb(0 33 71))',
        'fill-brand-secondary': 'var(--brand-500, rgb(42 96 183))',
        'fill-brand-tertiary': 'var(--brand-50, rgb(235 239 245))',
        'fill-negative-primary': 'var(--red-500, rgb(212 64 64))',
        'fill-negative-secondary': 'var(--red-300, rgb(228 134 134))',
        'fill-negative-tertiary': 'var(--red-50, rgb(244 207 207))',
        'fill-positive-primary': 'var(--green-500, rgb(80 185 117))',
        'fill-positive-secondary': 'var(--green-300, rgb(153 214 174))',
        'fill-positive-tertiary': 'var(--green-50, rgb(237 248 241))',
        'static-black': 'var(--primary-black, rgb(0 0 0))',
        'static-white': 'var(--primary-white, rgb(255 255 255))',
      },
      textColor: {
        attention: 'var(--orange-500, rgb(237 160 52))',
        negative: 'var(--red-500, rgb(212 64 64))',
        'on-accent': 'var(--primary-white, rgb(255 255 255))',
        'on-primary': 'var(--primary-white, rgb(255 255 255))',
        positive: 'var(--green-600, rgb(61 153 93))',
        primary: 'var(--neutral-900, rgb(23 23 23))',
        quaternary: 'var(--neutral-400, rgb(163 163 163))',
        secondary: 'var(--neutral-700, rgb(64 64 64))',
        tertiary: 'var(--neutral-500, rgb(115 115 115))',
        'accent-primary': 'var(--accent-600, rgb(61 153 93))',
        'accent-secondary': 'var(--accent-400, rgb(118 200 147))',
        'brand-primary': 'var(--brand-700, rgb(0 33 71))',
        'brand-secondary': 'var(--brand-500, rgb(42 96 183))',
      },
      stroke,
      borderColor: stroke,

      fontSize: {
        ...Object.fromEntries(
          ['s', 'm', 'l'].map((size) => [
            size,
            [
              `var(--text-${size}-size)`,
              {
                lineHeight: `var(--text-${size}-line-height)`,
              },
            ],
          ]),
        ),
        ...Object.fromEntries(
          ['2xs', 'xs', 'm', 's'].map((size) => [
            `heading-${size}`,
            [
              `var(--heading-${size}-size)`,
              {
                fontWeight: 600,
                lineHeight: `var(--heading-${size}-line-height)`,
              },
            ],
          ]),
        ),
      },

      boxShadow: {
        'border-brand': `0 0 0 1px ${stroke.brand}`,
      },

      aria: {
        invalid: 'invalid="true"',
      },

      keyframes: {
        expand: {
          from: { height: 0 },
          to: { height: 'var(--radix-collapsible-content-height)' },
        },
        collapse: {
          from: { height: 'var(--radix-collapsible-content-height)' },
          to: { height: 0 },
        },
      },
      animation: {
        expand: 'expand 300ms ease-out',
        collapse: 'collapse 300ms ease-out',
      },
      backgroundImage: {
        'product-page': 'linear-gradient(to bottom, #FFF, #FAFAFA 200px)',
      },
    },
  },
  plugins: [],
}
