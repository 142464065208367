import React from 'react'
import styled from 'styled-components'
import { Button, ButtonGroup } from '@/style/components/Button'
import Text from '@/style/components/LegacyText'
import { cn } from '@/utils/cn'

interface ConfiguratorQuestionProps {
  headline: string
  subline?: string
  description?: string
  value?: any
  options: {
    name: string
    value: any
    description?: string
    color?: string
    slug?: string
  }[]
  onChange?: (value: any) => void
  slug?: string
  buttonStyle?: React.CSSProperties
  nestedDescription?: boolean
}

const ConfiguratorQuestion = ({
  headline,
  subline,
  description: descriptionProp,
  value,
  options,
  onChange,
  buttonStyle = {},
  nestedDescription = false,
  slug,
}: ConfiguratorQuestionProps) => {
  const selectedValue = options.find((o) => o.value == value)
  const description =
    (selectedValue && selectedValue.description) ?? descriptionProp

  return (
    <GroupWrapper id={`${slug}`}>
      <h3 className="text-heading-2xs font-semibold text-secondary uppercase mb-2">
        {headline}
      </h3>
      {subline ? (
        <Text.ms as="div" color="light" id={`${slug}_SUBLINE`}>
          {subline}
        </Text.ms>
      ) : null}
      {!nestedDescription && description && (
        <p
          className="text-sm text-tertiary -mt-1 mb-2"
          style={selectedValue?.color ? { color: selectedValue?.color } : {}}
          id={`${slug}_DESCRIPTION`}
        >
          {description}
        </p>
      )}
      <ButtonGroup
        className={cn(options.length > 2 ? 'gap-1' : 'gap-2')}
        size={options.length}
        id={`${slug}_OPTIONS`}
      >
        {options.map((option) => {
          const isSelected = option.value == value
          return (
            <Button
              id={`${slug}_${option.slug}`}
              style={buttonStyle}
              type="button"
              key={option.value.toString()}
              className={cn(
                '!border !rounded-xl',
                isSelected &&
                  '!text-black !bg-fill-brand-tertiary !border-brand',
              )}
              appearance={isSelected ? 'primary-outline' : 'whitebg'}
              onClick={() => {
                if (onChange) {
                  value = option.value
                  onChange(option.value)
                }
              }}
            >
              <div
                className={cn(
                  'flex flex-row gap-4 !p-0',
                  !(nestedDescription && description) && 'justify-center',
                )}
              >
                <span className="font-semibold">{option.name}</span>
                {isSelected && nestedDescription && description && (
                  <span
                    className="text-sm text-secondary font-normal pt-[3px]"
                    id={`${slug}_DESCRIPTION`}
                  >
                    {description}
                  </span>
                )}
              </div>
            </Button>
          )
        })}
      </ButtonGroup>
    </GroupWrapper>
  )
}

const GroupWrapper = styled.section`
  text-align: center;
  margin: 3rem 0;

  div {
    padding-bottom: 1rem;
  }
`

export default ConfiguratorQuestion
