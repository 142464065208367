import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { FAQPageJsonLd } from 'next-seo'
import React, { useMemo } from 'react'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import {
  FaqStoryblok,
  RichtextStoryblok,
} from '@/types/storyblok-component-types'

export default function FAQ({ blok }: { blok: FaqStoryblok }) {
  const faqSchema = useMemo(() => {
    return blok.items?.map((item) => ({
      questionName: extractText(item.question),
      acceptedAnswerText: extractText(item.answer),
    }))
  }, [blok.items])

  return (
    <>
      {(blok.json_ld ?? true) && <FAQPageJsonLd mainEntity={faqSchema} />}

      <div
        {...storyblokEditable(blok)}
        // We need to set a negative margin to offset the SectionV1's padding being too large on smaller devices.
        className="max-w-screen-xl -mx-2 xs:-mx-6 md:px-6 lg:px-8"
      >
        <div className="mb-8 text-center">
          <RichTextParagraphStyles>
            <StoryblokRichText document={blok.title} />
          </RichTextParagraphStyles>
        </div>

        <div className="max-w-screen-sm mx-auto flex flex-col gap-2">
          {blok.items?.map((item) => (
            <StoryblokComponent blok={item} key={item._uid} />
          ))}
        </div>
      </div>
    </>
  )
}

function extractText(richText?: RichtextStoryblok): string {
  if (!richText || !richText.content) return ''

  const iterateContent = (content: RichtextStoryblok[]): string[] => {
    return content.map((node: RichtextStoryblok): string => {
      if (node.type === 'text' && node.text) {
        return node.text.replaceAll('"', '\\"')
      } else if (node.type === 'list_item' && node.content) {
        return '<li>' + iterateContent(node.content).join('') + '</li>'
      } else if (node.type === 'ordered_list' && node.content) {
        return '<ol>' + iterateContent(node.content).join('') + '</ol>'
      } else if (node.type === 'bullet_list' && node.content) {
        return '<ul>' + iterateContent(node.content).join('') + '</ul>'
      } else if (node.type === 'hard_break') {
        return '<br>'
      } else if (node.content) {
        return iterateContent(node.content).join('')
      }
      return ''
    })
  }

  return iterateContent(richText.content).join('')
}
