import CashyLogo from '../../../../icons/cashy-logo.svg'
import InfoIcon from '../../../../style/components/ErrorBox/ico_info.svg'
import Contact from '../../../common/assets/ico_contact.svg'
import IdIcon from '../../../common/assets/ico_id.svg'
import Password from '../../../common/assets/ico_password.svg'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import { Checkbox } from '@material-ui/core'
import { Form, Formik, FormikProps } from 'formik'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { DatePicker } from '@/app/common/components/Form/DatePicker'
import { SmartTextInput } from '@/app/common/components/Form/SmartTextInput'
import { ValidationItems } from '@/app/common/components/ValidationItems'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { useStoryblokConfig } from '@/app/common/context/storyblokConfigContext'
import { Button } from '@/components/Button'
import { UnderlinedLink } from '@/components/UnderlinedLink'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import useRegionCode from '@/helpers/useRegionCode'
import {
  ErrorBox,
  ErrorBoxLeft,
  ErrorBoxRight,
  ErrorBoxStyled,
} from '@/style/components/ErrorBox/ErrorBox'
import Text from '@/style/components/Text'
import { ERegionCode } from '@/types/gql/graphql'
import { dayjs } from '@/utils/time'
import { RegistrationModel } from './Register'
import SectionHead from './SectionHead'

const TermsContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;

  .MuiCheckbox-root {
    padding: 0;
    transform: translateX(-10%);
  }

  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: unset !important;
  }

  .MuiIconButton-colorSecondary:hover {
    background-color: unset !important;
  }
`

const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`

const TermsText = styled(Text.ms)`
  text-align: left;
  line-height: 1.4;
`

const PHONE_REGEX = /^\+?(\d|\s|-|\/)+$/g

const validationSchema = Yup.object().shape({
  firstname: Yup.string().min(2).required(),
  lastname: Yup.string().min(2).required(),
  phone: Yup.string()
    .min(6)
    .matches(PHONE_REGEX, 'common:errors.wrong_format')
    .required(),
  dateOfBirth: Yup.date().max(
    dayjs().subtract(18, 'year').hour(23).minute(23).toDate(),
    'common:errors.under_minimum_age',
  ),
  email: Yup.string().email().required(),
  password: Yup.string().min(6).required(),
  passwordRepeat: Yup.string()
    .required()
    .test(
      'common:errors.password_not_equal',
      'common:errors.password_not_equal',
      function (value) {
        return value === this.parent.password
      },
    ),
})

interface RegistrationFormProps {
  onSubmit: (state: RegistrationModel) => void
  gqlError:
    | {
        message: string
        code: string
      }
    | undefined
  initialState: RegistrationModel
}

export const RegistrationForm: React.FC<RegistrationFormProps> = (props) => {
  const { t } = useTranslation()
  const regionCode = useRegionCode()
  const isGermanyRegion = regionCode === ERegionCode.De

  const [termsDataPrivacyChecked, setTermsDataPrivacyChecked] = useState(false)

  const { config } = useStoryblokConfig()

  const buildValidationItems = (formikProps: FormikProps<any>) => {
    return [
      {
        valid: Yup.string().min(6).isValidSync(formikProps.values.password),
        description: t('common:errors.min_6_character'),
      },
      {
        valid:
          formikProps.values.password === formikProps.values.passwordRepeat &&
          formikProps.values.password.length > 0,
        description: t('common:errors.paswords_equal'),
      },
    ]
  }

  return (
    <Formik
      initialValues={props.initialState}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => {
        return (
          <Form>
            <SectionHead
              title={t('common:auth.personal_details')}
              description={t('common:auth.personal_details_description')}
              icon={<IdIcon className="text-accent-500" />}
            />
            <SmartTextInput
              formikProps={formikProps}
              type="text"
              name="firstname"
              placeholder={t('common:first_name')}
            />
            <SmartTextInput
              formikProps={formikProps}
              type="text"
              name="lastname"
              placeholder={t('common:auth.last_name')}
            />
            <DatePicker
              formikProps={formikProps}
              value={formikProps.values.dateOfBirth ?? null}
              maxDate={dayjs().subtract(18, 'year').toDate()}
              name="dateOfBirth"
              onChange={formikProps.getFieldHelpers('dateOfBirth').setValue}
              label={t('common:auth.birthDate')}
            />
            <SectionHead
              title={t('common:auth.contact_details')}
              description={t('common:auth.contact_details_description')}
              icon={<Contact className="text-accent-500" />}
            />
            <SmartTextInput
              formikProps={formikProps}
              type="text"
              name="phone"
              placeholder={t('common:auth.phone_details')}
            />
            <SmartTextInput
              formikProps={formikProps}
              type="email"
              name="email"
              placeholder={t('common:auth.email')}
            />
            <SectionHead
              title={t('common:auth.create_password')}
              description={t('common:auth.password_description')}
              icon={<Password className="text-accent-500" />}
            />
            <SmartTextInput
              formikProps={formikProps}
              type="password"
              name="password"
              placeholder={t('common:auth.password')}
            />
            <SmartTextInput
              formikProps={formikProps}
              type="password"
              name="passwordRepeat"
              placeholder={t('common:auth.passwordRepeat')}
            />
            <ValidationItems items={buildValidationItems(formikProps)} />

            <TermsContainer>
              <StyledCheckbox
                id="termsDataPrivacyChecked"
                checked={termsDataPrivacyChecked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTermsDataPrivacyChecked(e.target.checked)
                }
              />
              <TermsText
                as="label"
                color="gray"
                htmlFor="termsDataPrivacyChecked"
              >
                <Trans
                  i18nKey="common:terms_and_conditions.agb_and_privacy_policy"
                  values={{
                    companyName: isGermanyRegion
                      ? 'Cashy Germany GmbH'
                      : 'Cashy Austria GmbH',
                  }}
                  components={{
                    AGBLink: (
                      <UnderlinedLink
                        as="next-link"
                        href={getStoryBlokLink(config.agb)}
                        target="_blank"
                      />
                    ),
                    PrivacyPolicyLink: (
                      <UnderlinedLink
                        as="next-link"
                        href={getStoryBlokLink(config.privacy_policy)}
                        target="_blank"
                      />
                    ),
                  }}
                />
              </TermsText>
            </TermsContainer>
            {props.gqlError &&
              !props.gqlError.code?.includes('EMAIL_ALREADY_USED') && (
                <ErrorBox message={props.gqlError.message} />
              )}

            {props.gqlError &&
              props.gqlError.code?.includes('EMAIL_ALREADY_USED') && (
                <RegisterEmailAlreadyExistsErrorBox
                  email={formikProps.values.email}
                />
              )}
            <Button
              variant="primary"
              type="submit"
              disabled={formikProps.isSubmitting || !termsDataPrivacyChecked}
              className="w-full mt-4 px-6 py-2 flex justify-center items-center h-12 disabled:opacity-20"
            >
              {!formikProps.isSubmitting ? (
                t('common:auth.register')
              ) : (
                <div className="animate-spin [animation-timing-function:ease]">
                  <CashyLogo />
                </div>
              )}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

const ForgotPasswordLink = styled(Text.ms)`
  cursor: pointer;
  margin-left: 3px;
  color: #000af1;
`

export const RegisterEmailAlreadyExistsErrorBox = ({
  email,
}: {
  email: string
}) => {
  const { t } = useTranslation()
  const modalDialog = useContext(ModalDialogContext)

  return (
    <ErrorBoxStyled>
      <ErrorBoxLeft>
        <InfoIcon />
      </ErrorBoxLeft>
      <ErrorBoxRight>
        <Text.ms>
          {t('common:errors.ERROR_DESCRIPTION_EMAIL_ALREADY_USED')}
          <ForgotPasswordLink
            as="span"
            onClick={() =>
              modalDialog.open(
                <ForgotPassword email={email} fixedMobileFooter />,
                {
                  variant: 'full',
                },
              )
            }
          >
            {t('common:auth.password_reset')}
          </ForgotPasswordLink>
          ?
        </Text.ms>
      </ErrorBoxRight>
    </ErrorBoxStyled>
  )
}
