import { CustomVehicleFormData } from '../VehicleDealBooking'
import { Box, Checkbox, FormControlLabel } from '@material-ui/core'
import Trans from 'next-translate/Trans'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { UseFormReturnType } from '@/app/common/components/Form/useForm'
import { UnderlinedLink } from '@/components/UnderlinedLink'
import useRegionCode from '@/helpers/useRegionCode'
import { Button } from '@/style/components/Button'
import Text from '@/style/components/Text'
import { ERegionCode } from '@/types/gql/graphql'

const TermContainer = styled.div<{ mt?: string }>`
  display: flex;
  margin-top: ${(props) => (props.mt ? props.mt : '1.75rem')};

  .MuiFormControlLabel-root {
    display: flex;
    align-items: flex-start;
    margin: 0;
  }

  .MuiTypography-root {
    margin-left: 0.5rem;
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: unset !important;
  }

  .MuiIconButton-colorSecondary:hover {
    background-color: unset !important;
  }
`
const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`
const TermsText = styled(Text.md)`
  text-align: left;
  line-height: 1.4;
  width: 75%;
`
const TermsText2 = styled(Text.md)`
  text-align: left;
  line-height: 1.4;
`

interface TermsAndSubmitProps {
  createDealResult: any
  formikProps: UseFormReturnType<CustomVehicleFormData>
  onSubmit: (e: React.MouseEvent<HTMLElement>) => void
  noValuation?: boolean | null
  calculationLoading?: boolean | null
  documentLinks: AutoDocumentLinks
}

export interface AutoDocumentLinks {
  termsOfConditionsLink: string
  privacyPolicyLink: string
  withdrawalForm: string
  whatsappLink: string
  feeDocumentLink: string
}

const TermsAndSubmit: FunctionComponent<TermsAndSubmitProps> = ({
  formikProps,
  createDealResult,
  onSubmit,
  noValuation,
  calculationLoading,
  documentLinks,
}) => {
  const { dataPrivacyTermsAccepted, fernAbsGAccepted } = formikProps.values

  const submitDisabled =
    calculationLoading ||
    createDealResult.loading ||
    !dataPrivacyTermsAccepted ||
    !fernAbsGAccepted ||
    (noValuation && !formikProps.values.payoutAmount)

  const regionCode = useRegionCode()
  const companyName =
    regionCode === ERegionCode.At ? 'Cashy Austria GmbH' : 'Cashy Germany GmbH'

  return (
    <Box>
      <Box style={{ marginBottom: '1rem' }}>
        <TermContainer>
          <FormControlLabel
            control={<StyledCheckbox id="DATA_PRIVACY_TERMS_ACCEPTED" />}
            label={
              <TermsText color="gray" id="DATA_PRIVACY_TERMS_ACCEPTED_LABEL">
                <Trans
                  i18nKey="common:terms_and_conditions.agb_and_privacy_policy"
                  values={{
                    companyName,
                  }}
                  components={{
                    AGBLink: (
                      <UnderlinedLink
                        as="next-link"
                        href={documentLinks.termsOfConditionsLink}
                        target="_blank"
                      />
                    ),
                    PrivacyPolicyLink: (
                      <UnderlinedLink
                        as="next-link"
                        href={documentLinks.privacyPolicyLink}
                        target="_blank"
                      />
                    ),
                  }}
                />
              </TermsText>
            }
            name="dataPrivacyTermsAccepted"
            onChange={formikProps.handleChange}
            checked={formikProps.values.dataPrivacyTermsAccepted}
          />
        </TermContainer>
        <TermContainer mt="1rem">
          <FormControlLabel
            control={<StyledCheckbox id="FERN_ABS_GA_ACCEPTED" />}
            label={
              <TermsText2 color="gray" id="FERN_ABS_GA_ACCEPTED_LABEL">
                <Trans
                  i18nKey="common:terms_and_conditions.withdraw_distance_selling_act"
                  values={{
                    companyName,
                  }}
                  components={{
                    WithdrawSellingAct: (
                      <UnderlinedLink
                        as="next-link"
                        href={documentLinks.withdrawalForm}
                        target="_blank"
                      />
                    ),
                  }}
                />
              </TermsText2>
            }
            name="fernAbsGAccepted"
            onChange={formikProps.handleChange}
            checked={formikProps.values.fernAbsGAccepted}
          />
        </TermContainer>
      </Box>

      <Button
        disabled={submitDisabled}
        primary
        full
        onClick={onSubmit}
        id="CAR_REQUEST_SUBMIT_BUTTON"
      >
        <Trans
          i18nKey="vehicle:pawn.make_an_appointment"
          components={{ br: <br /> }}
        />
      </Button>
    </Box>
  )
}

export default TermsAndSubmit
