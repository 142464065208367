import { omit } from 'lodash'
import Image, { ImageLoader, ImageProps } from 'next/legacy/image'
import { useCallback, useMemo } from 'react'
import { AssetStoryblok } from '@/types/storyblok-component-types'

interface StoryblokImageProps
  extends Omit<ImageProps, 'src' | 'loader' | 'alt' | 'title'> {
  image: AssetStoryblok

  /**
   * Aspect ratio of the rendered image, used for cropping the image according to its focal point (width / height).
   * For example use 2 to have the image be two times as wide as it is high.
   *
   * Not available for svgs file.
   */
  aspectRatio?: number
}

/**
 * @deprecated use `NextStoryblokImage`.
 *
 * Image component to be used with images from Storyblok. IMPORTANT: Make sure everything works properly with images
 * of any aspect ratio, for example by setting the `aspectRatio` or `objectFit` properties.
 */
export default function StoryblokImage({
  image,
  aspectRatio,
  layout,
  ...props
}: StoryblokImageProps) {
  // see https://www.storyblok.com/faq/use-focal-point-set-in-storyblok
  // and https://www.storyblok.com/docs/image-service
  const loader = useStoryblokImageLoader({
    image,
    aspectRatio,
    width: props.width && Number(props.width),
    height: props.height && Number(props.height),
  })

  const { width, height } = useMemo(() => {
    if (layout === 'fill') return {}

    const match = image.filename.match(/(\d+)x(\d+)/)
    if (!match || match.length < 3) {
      console.error(image.filename)
      throw new Error('Failed to determine image size from url.')
    }

    return {
      width: Number.parseInt(match[1]),
      height: Number.parseInt(match[2]),
    }
  }, [image.filename, layout])

  // Handle svg files
  if (image.filename.endsWith('.svg')) {
    const styles = {
      ...(props.style ?? {}),
      objectFit: props.style?.objectFit ?? props.objectFit,
      objectPosition: props.style?.objectPosition ?? props.objectPosition,
      width: props.style?.width ?? props.width,
      height: props.style?.height ?? props.height,
    }

    switch (layout) {
      case undefined:
      case 'intrinsic':
        return (
          <img
            className={props.className}
            src={image.filename}
            style={styles}
          />
        )
      case 'fixed':
        return (
          <img
            src={image.filename}
            style={{
              width: props.width ?? props.style?.width ?? width, // fixed width and height
              height: props.height ?? props.style?.height ?? height,
              ...omit(styles, ['width', 'height', 'objectFit']),
            }}
          />
        )
      case 'responsive':
        return (
          <img
            className={props.className}
            src={image.filename}
            style={{
              position: 'static',
              minWidth: '100%',
              maxWidth: '100%',
              minHeight: '100%',
              maxHeight: ' 100%',
              ...styles,
            }}
          />
        )
      case 'fill':
        return (
          <div
            className={props.className}
            style={{
              position: 'relative',
              width: props.width ?? props.style?.width ?? width,
              height: props.height ?? props.style?.height ?? height,
            }}
          >
            <img
              src={image.filename}
              style={{
                position: 'absolute',
                minWidth: '100%',
                maxWidth: '100%',
                minHeight: '100%',
                maxHeight: ' 100%',
                ...omit(styles, ['width', 'height']),
              }}
            />
          </div>
        )
      default:
        throw new Error('Invalid layout')
    }
  }

  return (
    <Image
      {...props}
      layout={layout}
      width={props.width ?? width}
      height={props.height ?? height}
      src={image.filename}
      loader={loader}
      sizes={props.sizes}
      alt={image.alt}
      title={image.title}
    />
  )
}

interface StoryblokImageLoaderOptions {
  image?: AssetStoryblok
  aspectRatio?: number
  height?: number
  width?: number
}

export function useStoryblokImageLoader({
  image,
  aspectRatio,
  ...props
}: StoryblokImageLoaderOptions) {
  return useCallback<ImageLoader>(
    ({ src, width, quality = 75 }) => {
      const filters = [`quality(${quality})`]

      let height = 0 // fit by default
      if (aspectRatio) {
        height = Math.floor(width / aspectRatio)
      } else if (
        typeof props.height === 'number' &&
        props.height !== 0 &&
        typeof props.width === 'number'
      ) {
        height = Math.max(width * (props.height / props.width), 0)
      }

      // only add focus if both dimensions are given as otherwise it won't have any effect
      if (height !== 0 && image?.focus) {
        filters.push(`focal(${image.focus})`)
      }

      return `${src}/m/${width}x${height}/filters:${filters.join(':')}`
    },
    [image, aspectRatio, props.height, props.width],
  )
}
