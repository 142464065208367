import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { ComponentProps } from 'react'
import { cn } from '@/utils/cn'

export function RightArrow({
  className,
  ...props
}: ComponentProps<typeof ArrowForwardIosIcon>) {
  return (
    <ArrowForwardIosIcon
      className={cn('text-secondary align-middle', className)}
      {...props}
    />
  )
}

export function LeftArrow({
  className,
  ...props
}: ComponentProps<typeof ArrowBackIosIcon>) {
  return (
    <ArrowBackIosIcon
      className={cn('text-black align-middle', className)}
      {...props}
    />
  )
}
