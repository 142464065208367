import { CustomDealFormData } from '../ItemList'
import { Field, FieldValidator, FormikProps } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { InputErrorMessage } from '@/app/common/components/Form'
import { SmartTextInput } from '@/app/common/components/Form'
import { Button, ButtonGroup } from '@/style/components/Button'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import { cn } from '@/utils/cn'
import { getTranslatedValidationError } from '@/utils/error'

interface Props {
  formikProps: FormikProps<CustomDealFormData>
  type?: string
  name: string
  label: string
  questionIndex: number
  info: string
  itemIndex: number
  allowEnterAnswer?: boolean
  options: {
    value: string | number
    label: string
    description?: string
  }[]
  validate?: FieldValidator
  onTouched: (questionIndex: number) => void
}

const OTHER_OPTION = 'OTHER_OPTION'

export const TextOptionQuestion = ({
  formikProps,
  type = 'text',
  label,
  itemIndex,
  name,
  options,
  info,
  allowEnterAnswer,
  questionIndex,
  validate,
  onTouched,
}: Props) => {
  const { t } = useTranslation()
  const field = formikProps.getFieldMeta(name)
  const error =
    field.error && (field.touched || formikProps.submitCount > 0)
      ? field.error
      : undefined
  const [isChooseOtherOption, chooseOtherOption] = useState(!options.length) // If have no option, auto set to be other answer
  const availableOptions =
    allowEnterAnswer && options.length
      ? [
          ...options,
          {
            label: t('customDeal:other'),
            value: OTHER_OPTION,
          },
        ]
      : options
  const ref = useRef({ touched: false })

  useEffect(() => {
    if (!ref.current.touched && field.touched) {
      ref.current.touched = true
      onTouched(questionIndex)
    }

    return () => {
      if (!field.touched) {
        // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current.touched = false
      }
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.touched, questionIndex])

  const checkIsSelectedOption = (value: string, optionValue: string) => {
    if (optionValue === value) return true
    return optionValue === OTHER_OPTION && isChooseOtherOption
  }

  return (
    <Wrapper>
      <QuestionTitle weight="medium">{label}</QuestionTitle>

      {info && (
        <QuestionInfo as="div" color="light">
          {info}
        </QuestionInfo>
      )}

      <Field
        name={name}
        validate={(value: number) => {
          if (options.length && validate) {
            validate(options[value]?.value)
          }
        }}
      >
        {() => (
          <ButtonGroup size={availableOptions.length}>
            {availableOptions.map((option, index) => (
              <Button
                type="button"
                id={`CUSTOM_DEAL_ITEM_${itemIndex}_QUESTION_${questionIndex}_OPTION_BUTTON_${index}`}
                key={option.value.toString()}
                className={cn(
                  checkIsSelectedOption(
                    field.value as string,
                    option.value as string,
                  ) &&
                    '!text-black !bg-fill-accent-tertiary !border-accent-500',
                )}
                appearance={
                  checkIsSelectedOption(
                    field.value as string,
                    option.value as string,
                  )
                    ? 'primary-outline'
                    : 'whitebg'
                }
                onClick={() => {
                  if (option.value !== OTHER_OPTION) {
                    formikProps.setFieldValue(name, option.value)
                    chooseOtherOption(false)
                  } else {
                    formikProps.setFieldValue(name, null)
                    chooseOtherOption(true)
                  }
                }}
              >
                {option.label}
                {option.description &&
                  checkIsSelectedOption(
                    field.value as string,
                    option.value as string,
                  ) && (
                    <NestedDescription>{option.description}</NestedDescription>
                  )}
              </Button>
            ))}
          </ButtonGroup>
        )}
      </Field>

      {isChooseOtherOption && (
        <>
          <OtherInput
            id={`CUSTOM_DEAL_ITEM_${itemIndex}_QUESTION_${questionIndex}_OTHER_OPTION_INPUT`}
            formikProps={formikProps}
            validate={validate}
            name={name}
            type={type}
            placeholder={label}
            defaultEmptyValue
          />
        </>
      )}

      {error && !isChooseOtherOption && (
        <InputErrorMessage message={getTranslatedValidationError(error, t)} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  margin: 1.5rem 0;
`

const QuestionTitle = styled(Text.h3)`
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.3rem;

  ${media.xs} {
    font-size: 0.875rem;
  }

  ${media.sm} {
    font-size: 1rem;
  }
`

const QuestionInfo = styled(Text.ms)`
  margin-bottom: 0.5rem;
`

const OtherInput = styled(SmartTextInput)`
  margin-top: 0.5rem;
`

const NestedDescription = styled(Text.ms).attrs({ as: 'p' })`
  margin-top: 0.625rem;
  margin-bottom: -0.625rem;
  margin-left: -1.4rem;
  margin-right: -1.4rem;
  background-color: white;
  padding: 6px 15px;
  border-radius: 3px;
`
