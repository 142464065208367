import { useStoryblokConfig } from '../../context/storyblokConfigContext'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import StoryblokRichText from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { SectionInner } from '@/style/components/Section'
import FaceBookLogo from './assets/FaceBookLogo.svg'
import InstagramLogo from './assets/InstagramLogo.svg'
import LinkedInLogo from './assets/LinkedInLogo.svg'
import SnapchatLogo from './assets/SnapchatLogo.svg'
import TikTopLogo from './assets/TikTopLogo.svg'
import XLogo from './assets/XLogo.svg'
import YouTubeLogo from './assets/YouTubeLogo.svg'
import FooterLogo from './assets/footer_logo.svg'

export default function Footer() {
  const router = useRouter()
  const {
    config: {
      footer_columns,
      disclaimer,
      tiktok,
      facebook,
      instagram,
      linkedin,
      youtube,
      snapchat,
      x,
      background_color,
      content_color,
    },
  } = useStoryblokConfig()

  const isFinalizeAccountSetup = router.pathname.includes(
    '/finalize-account-setup',
  )

  if (isFinalizeAccountSetup) {
    return
  }

  return (
    <footer className="w-full p-3 md:p-6">
      <div
        style={{
          backgroundColor: background_color?.color,
        }}
        className="relative overflow-hidden [&>b]:font-semibold py-8 rounded-xl max-w-[1500px] mx-auto"
      >
        <SectionInner sizing="wide">
          <div className="flex flex-col md:flex-row flex-wrap mb-8">
            {footer_columns?.map((item) => {
              if (item.component === 'footer-column') {
                return (
                  <div
                    className="flex flex-1 flex-col mr-6 mb-8 [&:last-child]:mr-0 md:mb-0"
                    key={item.key}
                  >
                    <span
                      style={{ color: content_color?.color }}
                      className="font-bold leading-6 mb-3 text-heading-xs"
                    >
                      {item.column_heading}
                    </span>
                    {item.column?.map((col) => {
                      if (col.component === 'footer-text') {
                        return (
                          <RichTextContainer color={content_color?.color}>
                            <StoryblokRichText
                              key={col.key}
                              document={col.content}
                            />
                          </RichTextContainer>
                        )
                      } else if (col.component === 'footer-link') {
                        return (
                          <div className="mb-2">
                            <Link
                              style={{ color: content_color?.color }}
                              className="text-sm leading-6 no-underline hover:underline"
                              href={getStoryBlokLink(col?.footer_link)}
                            >
                              {col.label}
                            </Link>
                          </div>
                        )
                      }
                    })}
                  </div>
                )
              }
              return null
            })}
          </div>
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex items-start flex-col mb-8 md:flex-row md:items-center md:mb-0">
              <div className="flex flex-row items-center">
                <FooterLogo
                  style={{
                    color: content_color?.color,
                  }}
                />
                <LanguageSelector
                  isInFooter
                  footerContentColor={content_color?.color}
                />
              </div>

              <span
                style={{ color: content_color?.color }}
                className="text-sm ml-0 mt-8 md:ml-2 md:mt-0"
              >
                {disclaimer}
              </span>
            </div>

            <div className="flex items-center gap-4">
              {facebook?.url && (
                <a rel="noreferrer" href={facebook.url} target="_blank">
                  <FaceBookLogo
                    width="1.5rem"
                    height="1.5rem"
                    fill={content_color?.color}
                  />
                </a>
              )}
              {linkedin?.url && (
                <a rel="noreferrer" href={linkedin.url} target="_blank">
                  <LinkedInLogo
                    width="1.5rem"
                    height="1.5rem"
                    fill={content_color?.color}
                  />
                </a>
              )}
              {instagram?.url && (
                <a rel="noreferrer" href={instagram.url} target="_blank">
                  <InstagramLogo
                    width="1.5rem"
                    height="1.5rem"
                    fill={content_color?.color}
                  />
                </a>
              )}
              {tiktok?.url && (
                <a rel="noreferrer" href={tiktok.url} target="_blank">
                  <TikTopLogo
                    width="1.5rem"
                    height="1.5rem"
                    fill={content_color?.color}
                  />
                </a>
              )}
              {youtube?.url && (
                <a rel="noreferrer" href={youtube.url} target="_blank">
                  <YouTubeLogo
                    width="1.5rem"
                    height="1.5rem"
                    fill={content_color?.color}
                  />
                </a>
              )}
              {snapchat?.url && (
                <a rel="noreferrer" href={snapchat.url} target="_blank">
                  <SnapchatLogo
                    width="1.5rem"
                    height="1.5rem"
                    fill={content_color?.color}
                  />
                </a>
              )}
              {x?.url && (
                <a rel="noreferrer" href={x.url} target="_blank">
                  <XLogo
                    width="1.5rem"
                    height="1.5rem"
                    fill={content_color?.color}
                  />
                </a>
              )}
            </div>
          </div>
        </SectionInner>
      </div>
    </footer>
  )
}

const RichTextContainer = styled.div<{ color: string | undefined }>`
  & p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding-bottom: 0.5rem;
    ${(props) => props.color && `color: ${props.color};`}
  }

  & > p:first-of-type {
    margin-top: 0 !important;
  }

  & > p:last-of-type {
    margin-bottom: 0 !important;
  }

  & a {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-decoration: none;
    padding-bottom: 0.5rem;
    ${(props) => props.color && `color: ${props.color};`}
  }
`
