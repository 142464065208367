import * as Sentry from '@sentry/nextjs'
import {
  MutableSnapshot,
  RecoilState,
  SetterOrUpdater,
  Snapshot,
  useRecoilState,
} from 'recoil'
import { useIsSSR } from '@/helpers/useIsSSR'

interface PersistStateArg {
  storage: Storage
  atoms: RecoilState<any>[]
}

export function persistState(snapshot: Snapshot, args: PersistStateArg[]) {
  try {
    for (const { storage, atoms } of args) {
      if (typeof storage !== 'undefined') {
        const persistedRecoilState: Record<string, any> = {}

        for (const atom of atoms) {
          persistedRecoilState[atom.key] = snapshot.getLoadable(atom).contents
        }

        storage.setItem('recoilState', JSON.stringify(persistedRecoilState))
      }
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

interface RestoreClientArg {
  atoms: RecoilState<any>[]
  storage: Storage
}

export function restoreClientState(args: RestoreClientArg[]) {
  return ({ set }: MutableSnapshot) => {
    try {
      for (const { storage, atoms } of args) {
        if (typeof storage !== 'undefined') {
          const _recoilState = storage.getItem('recoilState')

          if (typeof _recoilState === 'string') {
            const recoilState = JSON.parse(_recoilState)

            for (const atom of atoms) {
              if (typeof atom.key !== 'undefined') {
                set(atom, recoilState[atom.key])
              }
            }
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}

export function useRecoilStateWithSSRFallback<T>(
  recoilState: RecoilState<T>,
  fallback: T,
): [T, SetterOrUpdater<T>] {
  const [state, setState] = useRecoilState(recoilState)
  const isSSR = useIsSSR()
  return [isSSR ? fallback : state, setState]
}
