import NextStoryblokImage from '../../StoryblokImage/NextStoryblokImage'
import ArrowSmall from '../assets/arrow_small.svg'
import * as Collapsible from '@radix-ui/react-collapsible'
import { storyblokEditable } from '@storyblok/react'
import Link from 'next/link'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { MenuItemGroupV2Storyblok } from '@/types/storyblok-component-types'

export default function MenuItemGroup({
  blok,
}: {
  blok: MenuItemGroupV2Storyblok
}) {
  return (
    <Collapsible.Root
      {...storyblokEditable(blok)}
      className="bg-[#F3F3F3] rounded-xl p-4"
    >
      <Collapsible.Trigger className="w-full flex flex-row gap-2 text-sm items-center font-medium group">
        {blok.icon && (
          <NextStoryblokImage
            image={blok.icon}
            width={24}
            height={24}
            className="size-6 object-contain"
          />
        )}
        {blok.title}
        <ArrowSmall className="text-black ml-auto rotate-90 group-data-[state=open]:-rotate-90 transition-transform duration-100" />
      </Collapsible.Trigger>
      <Collapsible.Content className="data-[state=open]:animate-expand data-[state=closed]:animate-collapse overflow-hidden">
        <div className="flex flex-col gap-3 pl-12 pt-6">
          {blok.menuItems.map((subItem) => (
            <Link
              href={getStoryBlokLink(subItem.link)}
              key={subItem.title}
              className="text-sm py-2"
            >
              {subItem.title}
            </Link>
          ))}
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  )
}
