import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { UnderlinedLink } from '@/components/UnderlinedLink'
import useWhatsappNumber from '@/helpers/useWhatsappNumber'
import { SectionInner } from '@/style/components/Section'
import Text from '@/style/components/Text'

interface Props {
  whatsappNumber?: string
  onClickWhatsappNumber?: () => void
}

export default function WhatsAppSection({
  whatsappNumber,
  onClickWhatsappNumber,
}: Props) {
  const { t, lang } = useTranslation()

  const defaultWhatsappNumber = useWhatsappNumber()

  return (
    <SectionInner textAlign="center" sizing="narrow" spacingBefore>
      <Text.md weight="semibold" as="h2" transform="uppercase">
        {t('common:whatsapp.special_page.headline')}
      </Text.md>
      <Text.p>{t('common:whatsapp.special_page.paragraph')}</Text.p>
      <UnderlinedLink
        weight="bold"
        href={`https://api.whatsapp.com/send?phone=${
          whatsappNumber ?? defaultWhatsappNumber
        }&text=${t('common:whatsapp.initial_message')}&lang=${lang}`}
        target="_blank"
        rel="noreferrer"
        onClick={onClickWhatsappNumber}
      >
        {t('common:whatsapp.special_page.cta_link')}
      </UnderlinedLink>
    </SectionInner>
  )
}
