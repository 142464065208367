import CashyLogo from '../../../icons/cashy-logo.svg'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const SvgContainer = styled.div`
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`

const LastFallbackImage: FunctionComponent = () => (
  <SvgContainer>
    <CashyLogo />
  </SvgContainer>
)

export default LastFallbackImage
