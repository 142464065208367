import AccountIcon from '../assets/icon_account.svg'
import Link from 'next/link'

const headerPrefix = 'header'

interface Props {
  isLoggedIn: boolean
  onLoginClick: () => void
}

export function MobileAccountButton({ isLoggedIn, onLoginClick }: Props) {
  return isLoggedIn ? (
    <Link
      id={`${headerPrefix}_profile`}
      href="/profile"
      className="h-full w-10 flex justify-center items-center"
    >
      <AccountIcon className="fill-accent-500 text-accent-500" />
    </Link>
  ) : (
    <button
      className="h-full w-10 flex justify-center items-center"
      onClick={onLoginClick}
    >
      <AccountIcon />
    </button>
  )
}
