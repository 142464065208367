import { useRegisterCustomer } from '../../auth.service'
import AuthDialog from '../AuthDialog/AuthDialog'
import Login from '../Login/Login'
import LoginPrompt from '../LoginPrompt/LoginPrompt'
import { RegistrationSuccess } from '../RegistrationSuccess/RegistrationSuccess'
import { Box } from '@material-ui/core'
import { NextPage } from 'next'
import useTranslation from 'next-translate/useTranslation'
import React, { useContext, useState } from 'react'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import * as tracking from '@/helpers/tracking'
import Text from '@/style/components/LegacyText'
import { RegisterCustomerMutation } from '@/types/gql/graphql'
import { cn } from '@/utils/cn'
import { parseApolloErrorPretty } from '@/utils/error'
import { RegistrationForm } from './RegistrationForm'

export interface RegistrationModel {
  firstname: string
  lastname: string
  phone: string
  email: string
  password: string
  passwordRepeat: string
  dateOfBirth: Date | null
  aquiredBy?: string | null
}

export interface RegisterProps {
  afterRegisterAction?: (customerId: any) => void
  barTitle?: string
  headline?: string | JSX.Element
  description?: string
  success?: {
    barTitle?: string
    title?: string
    description?: string
  }
  closeAfterSuccess?: boolean
}

const Register: NextPage<RegisterProps> = (props) => {
  const { description, headline, barTitle } = props

  const modalDialog = useContext(ModalDialogContext)

  const { t } = useTranslation()

  const onDismiss = () => {
    modalDialog.close()
  }

  const onRegisterSuccess = (data: RegisterCustomerMutation) => {
    tracking.trackEvent('registration')

    if (props.closeAfterSuccess) {
      modalDialog.close()
    }
    if (props.afterRegisterAction) {
      props.afterRegisterAction(data.registerCustomer._id)
    }
  }

  return (
    <AuthDialog
      navBar={{
        title: barTitle ?? t('common:auth.create_account'),
        link: {
          description: t('common:auth.account_already'),
          text: t('common:auth.login'),
          onClick: () =>
            modalDialog.open(<Login register={<Register {...props} />} />, {
              variant: 'full',
            }),
        },
      }}
      content={
        <RegisterBody
          className="mt-0 sm:mt-5 md:mt-24"
          footer={
            <LoginPrompt
              content={
                <Login
                  register={<Register {...props} />}
                  afterLoginAction={props.afterRegisterAction}
                />
              }
            />
          }
          headline={headline}
          description={description}
          onRegisterSuccess={onRegisterSuccess}
          onDismiss={onDismiss}
        />
      }
    />
  )
}

interface RegisterBodyProps {
  className?: string
  headline?: string | JSX.Element
  description?: string
  onRegisterSuccess?: (data: RegisterCustomerMutation) => void
  onDismiss?: () => void
  footer?: React.ReactNode
  skipSuccessStep?: boolean
}

export function RegisterBody({
  className,
  headline,
  description,
  onRegisterSuccess,
  onDismiss,
  footer,
  skipSuccessStep,
}: RegisterBodyProps) {
  const [registrationStep, setRegistrationStep] = useState<'first' | 'success'>(
    'first',
  )

  const { t } = useTranslation()

  const [registrationState] = useState<RegistrationModel>({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    password: '',
    passwordRepeat: '',
    dateOfBirth: null,
  })

  const { registerCustomer, error } = useRegisterCustomer()

  const onRegisterSubmit = async (values: RegistrationModel) => {
    const res = await registerCustomer({
      variables: values,
    }).catch(() => {})

    if (res && res.data) {
      if (!skipSuccessStep) {
        setRegistrationStep('success')
      }
      onRegisterSuccess?.(res.data)
    }
  }

  const gqlErrorPretty = error ? parseApolloErrorPretty(error, t) : undefined

  return (
    <div className={cn('text-center', className)}>
      {registrationStep != 'success' && (
        <>
          <Text.md
            weight="semibold"
            style={{
              fontSize: '1.375rem',
              marginBottom: '0.75rem',
              display: 'inline-block',
            }}
            uppercase
          >
            {headline}
          </Text.md>
          <Box textAlign="center" marginBottom="0.75rem">
            {description && (
              <Text.md style={{ fontSize: '0.875rem' }}>{description}</Text.md>
            )}
          </Box>
          {footer}
        </>
      )}
      {
        {
          first: (
            <RegistrationForm
              initialState={registrationState}
              gqlError={gqlErrorPretty}
              onSubmit={(values) => {
                onRegisterSubmit(values)
              }}
            />
          ),
          success: (
            <RegistrationSuccess
              title={t('common:auth.account_created')}
              description={t('common:auth.account_created_description')}
              onClickReturn={() => onDismiss?.()}
              buttonLabel={t('common:auth.back_to_cashy')}
            />
          ),
        }[registrationStep]
      }
    </div>
  )
}

export default Register
