import { storyblokEditable } from '@storyblok/react'
import { motion } from 'framer-motion'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import Text from '@/style/components/LegacyText'
import { delayedVariant } from '@/style/components/ScrollReveal'
import { media } from '@/style/helpers'
import { StepsItemStoryblok } from '@/types/storyblok-component-types'
import StepArrow from './assets/step_arrow.svg'

export default function StepsItem({
  blok,
  index,
}: {
  blok: StepsItemStoryblok
  index: number
}) {
  const imagePosition = index % 2 === 0 ? 'left' : 'right'

  const backgroundColor = useMemo(() => {
    const bgColor1 = blok.image_background_color_1?.color
    if (!bgColor1) {
      return undefined
    }

    const bgColor2 = blok.image_background_color_2?.color
    if (!bgColor2) {
      return bgColor1
    }

    return `linear-gradient(150.56deg, ${bgColor1} -28.6%, ${bgColor2} 68.77%)`
  }, [blok.image_background_color_1, blok.image_background_color_2])

  return (
    <Wrapper imagePosition={imagePosition} {...storyblokEditable(blok)}>
      <StepArrowImage>
        <StepArrow className="inline text-accent-500" />
      </StepArrowImage>
      <StepWrapper imagePosition={imagePosition}>
        <ImageWrapper>
          <ImageInnerWrapper background={backgroundColor}>
            {blok.image?.filename && (
              <Image>
                <StoryblokImage
                  image={blok.image}
                  sizes="(min-width: 1000px) 400px, (min-width: 768px) 265px, (min-width: 717px) 477px, (min-width: 1px) 67vw, 10vw"
                  layout="fill"
                />
              </Image>
            )}
          </ImageInnerWrapper>
        </ImageWrapper>
        <CaptionWrapper>
          <IndexNumberWrapper
            className="bg-fill-accent-primary"
            variants={delayedVariant('scale', 0.25)}
          >
            <IndexNumber className="text-on-primary" weight="semibold">
              {index + 1}
            </IndexNumber>
          </IndexNumberWrapper>
          <StoryblokRichText document={blok.caption} />
        </CaptionWrapper>
      </StepWrapper>
    </Wrapper>
  )
}

const StepWrapper = styled.div<{ imagePosition: 'left' | 'right' | '' }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${media.sm} {
    flex-direction: ${({ imagePosition }) =>
      imagePosition === 'left' ? 'row' : 'row-reverse'};
  }
`

const Image = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ImageInnerWrapper = styled.div<{ background?: string }>`
  width: 75%;
  margin: 0 auto 1rem;
  background: ${({ background }) => background};
  clip-path: circle(50% at 50% 50%);
  overflow: hidden;
  position: relative;
  aspect-ratio: 1 / 1;

  ${media.md} {
    width: 25em;
    height: 25em;
  }
`

const ImageWrapper = styled.div`
  flex: 1 0 0px;

  ${media.sm} {
    flex: 5 0 0px;
  }

  display: flex;
  justify-content: center;
`

const CaptionWrapper = styled.div`
  flex: 1 0 0px;

  ${media.sm} {
    flex: 5 0 0px;
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3rem;

  b {
    font-weight: 500;
  }

  ${media.sm} {
    margin-bottom: 0;
    text-align: left;
  }
`

const StepArrowImage = styled.div`
  margin: -3rem auto;
  display: block;
  position: absolute;
  right: 1rem;
  top: -0.7rem;
  width: 15%;
  transform: rotate(-30deg) scale(0.5);
  text-align: center;

  g {
    stroke-width: 6;
    ${media.xs} {
      stroke-width: 5;
    }
    ${media.sm} {
      stroke-width: 3;
    }
  }

  ${media.sm} {
    top: 0;
    right: 0;
    position: relative;
    margin: -3rem auto;
    width: 100%;
    transform: rotate(0);
  }
`

const IndexNumber = styled(Text.xl)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;

  ${media.md} {
    font-size: 2rem;
  }
`

const IndexNumberWrapper = styled(motion.div)`
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  left: 0;

  * {
    padding-top: 0.1em;
    line-height: 1em;
  }

  ${media.sm} {
    position: relative;
    margin-right: 1rem;
  }
`

const Wrapper = styled.div<{ imagePosition: 'left' | 'right' | '' }>`
  align-items: center;
  position: relative;
  margin: 0;

  ${({ imagePosition }) =>
    imagePosition === 'right' &&
    css`
      ${StepArrowImage} {
        right: auto;
        left: 1rem;
        transform: rotate(30deg) rotateY(180deg) scale(0.5);

        ${media.sm} {
          left: 0;
          transform: rotateY(-180deg);
        }
      }

      ${IndexNumberWrapper} {
        left: auto;
        right: 0;
      }
    `}
`
