import styled from 'styled-components'
import { media } from '@/style/helpers'
import BlogArrow from './assets/blog_arrow.svg'

export const ReadMore = ({
  text,
  isCenter,
  hideArrow,
}: {
  text: string
  isCenter?: boolean
  hideArrow?: boolean
}) => {
  return (
    <Container $isCenter={isCenter}>
      <span>{text}</span>
      {!hideArrow && (
        <span className="ml-[6px] inline-flex justify-center text-fill-accent-primary">
          <BlogArrow className="inline" />
        </span>
      )}
    </Container>
  )
}

const Container = styled.div<{ $isCenter?: boolean }>`
  width: 100%;
  font-weight: 600;
  margin-bottom: ${(props) => (props.$isCenter ? '1rem' : '1rem')};
  margin-top: 0;
  ${(props) =>
    props.$isCenter ? 'display: flex; justify-content: center;' : ''}

  ${media.md} {
    margin-bottom: ${(props) => (props.$isCenter ? '2.5rem' : '1rem')};
    margin-top: ${(props) => (props.$isCenter ? '4.5rem' : '0')};
  }
`
