import LanguageSelector from '../LanguageSelector/LanguageSelector'
import { MobileAccountButton } from '@/app/common/components/Header/AccountButton/MobileAccountButton'
import { HeaderLogo } from '@/app/common/components/Header/Logo/Logo'
import { SearchButton } from '@/app/common/components/Header/SeachButton/SearchButton'
import { cn } from '@/utils/cn'
import MenuButton from './MenuButton/MenuButton'
import styles from './header.module.css'

type Props = {
  menuOpen: boolean
  toggleMenu: () => void
  isCheckout?: boolean
  onLoginClick: () => void
  isLoggedIn: boolean
}

export const MobilePrimaryHeader = ({
  menuOpen,
  toggleMenu,
  isCheckout,
  onLoginClick,
  isLoggedIn,
}: Props) => {
  return (
    <div
      className={cn(styles.mobilePrimaryHeader, 'md:hidden grid bg-white z-0')}
    >
      <div className="[grid-area:logo] h-full flex items-center">
        <MenuButton menuOpen={menuOpen} toggleMenu={toggleMenu} />
        <HeaderLogo className="pl-2 pr-1" />
      </div>

      <div
        className={cn(
          '[grid-area:nav] h-full flex flex-row justify-end items-center gap-0.5',
          isCheckout && 'hidden',
        )}
      >
        <SearchButton />
        <MobileAccountButton
          isLoggedIn={isLoggedIn}
          onLoginClick={onLoginClick}
        />
        <LanguageSelector />
      </div>
    </div>
  )
}
