import Head from 'next/head'
import { ImageLoader } from 'next/image'
import invariant from 'tiny-invariant'
import { AssetStoryblok } from '@/types/storyblok-component-types'
import { useStoryblokImageLoader } from './StoryblokImage'

interface Image {
  image: AssetStoryblok

  /**
   * Used to set srcset values if the image will not be full width.
   *
   * @default `1`
   **/
  scale?: number
}

interface ImageWithMinWidth extends Image {
  /** Including css unit, e.g. `420px`. */
  minWidth: string
}

export interface StoryblokPictureProps {
  sizes: string
  className?: string
  images: [Image, ...ImageWithMinWidth[]]
}

export default function StoryblokPicture({
  images,
  className,
  sizes,
}: StoryblokPictureProps) {
  const loader = useStoryblokImageLoader({})

  return (
    <picture key={images.map((i) => i.image.id).join('_')}>
      {[...images].reverse().map((image) => (
        <source
          media={
            'minWidth' in image ? `(min-width: ${image.minWidth})` : undefined
          }
          srcSet={buildSrcSet(image.image, loader, image.scale)}
        />
      ))}

      <Head>
        {images.map((image, index) => {
          const mediaQueries: string[] = []
          if ('minWidth' in image) {
            mediaQueries.push(`(min-width: ${image.minWidth})`)
          }

          // if not last image set a max width
          if (index < images.length - 1) {
            const biggerImage = images[index + 1]
            invariant('minWidth' in biggerImage)

            const sizeMatch = biggerImage.minWidth.match(CssSizeRegExp)
            invariant(sizeMatch)

            const [, numberString, unit] = sizeMatch
            const maxWidthNumber = Number.parseFloat(numberString) - 0.001

            mediaQueries.push(`(max-width: ${maxWidthNumber}${unit})`)
          }

          return (
            <link
              rel="preload"
              href={image.image.filename}
              sizes={sizes}
              imageSrcSet={buildSrcSet(image.image, loader, image.scale)}
              as="image"
              media={mediaQueries.join(' and ') || undefined}
            />
          )
        })}
      </Head>

      <img
        className={className}
        sizes={sizes}
        alt={images[0].image.alt}
        title={images[0].image.title}
        src={images[0].image.filename}
      />
    </picture>
  )
}

const CssSizeRegExp = /^(\d+)([a-z]*)$/

// TODO CAS-4027: replace with getImageProps API
// https://nextjs.org/docs/14/app/api-reference/components/image#getimageprops
function buildSrcSet(
  image: AssetStoryblok,
  loader: ImageLoader,
  multiplier = 1,
) {
  return deviceSizes
    .map((w) => {
      const width = w * multiplier
      return `${loader({ src: image.filename, width, quality: 90 })} ${width}w`
    })
    .join(', ')
}

const deviceSizes = [640, 750, 828, 1080, 1200, 1920, 2048, 3840]
