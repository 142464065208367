import { createContext, useContext, useMemo } from 'react'

type CustomModalStyles = {
  content?: React.CSSProperties
  overlay?: React.CSSProperties
}

export interface ModalOptions {
  onAfterClose?: () => void
  customStyles?: CustomModalStyles
  variant?: 'info' | 'full'
  hasWrapper?: boolean
  fixedMobileFooter?: boolean
  shouldCloseOnEsc?: boolean
  shouldCloseOnOverlayClick?: boolean
  customClass?: string
}

interface ModalDialogContextType {
  isOpen: boolean
  close: () => void
  open: (content: JSX.Element, options?: ModalOptions) => void
}

export const ModalDialogContext = createContext<ModalDialogContextType>({
  isOpen: false,
  close: () => {},
  open: () => {},
})

export const useModalDialogContext = () => {
  const context = useContext(ModalDialogContext)
  if (!context) throw new Error('Need to be wrapped by ModalDialogProvider')

  return useMemo(
    () => ({
      open: context.open,
      close: context.close,
      isOpen: context.isOpen,
    }),
    [context],
  )
}
