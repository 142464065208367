import { useCallback, useContext } from 'react'
import { useRecoilValue } from 'recoil'
import { customerState } from '@/app/auth/auth.state'
import Register, { RegisterProps } from '@/app/auth/components/Register'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { Scalars } from '@/types/gql/graphql'

type ConfigurableRegisterProps = Pick<
  RegisterProps,
  'headline' | 'barTitle' | 'description'
>

const useEnsureCustomerLoggedIn = (): ((
  props: ConfigurableRegisterProps,
) => Promise<Scalars['ObjectId']['output'] | null>) => {
  const customer = useRecoilValue(customerState)
  const modal = useContext(ModalDialogContext)

  return useCallback(
    async (props: ConfigurableRegisterProps): Promise<string | null> => {
      if (customer) return customer._id

      return new Promise((resolve) =>
        modal.open(
          <Register
            {...props}
            afterRegisterAction={resolve}
            closeAfterSuccess
          />,
          {
            variant: 'full',
            onAfterClose: () => resolve(null),
          },
        ),
      )
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer, modal.open],
  )
}

export default useEnsureCustomerLoggedIn
