import StoryblokPicture from '../../app/common/components/StoryblokImage/StoryblokPicture'
import config from '@/../tailwind.config'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { CarPawnHeroStoryblok } from '@/types/storyblok-component-types'
import LoanAmountCard from './LoanAmountCard'

export default function CarPawnHero({ blok }: { blok: CarPawnHeroStoryblok }) {
  // required properties missing as blok is not fully configured
  if (!blok.mobile_image || !blok.tablet_image || !blok.desktop_image) {
    return
  }

  return (
    <div
      className="relative w-full m-4 max-w-[1400px] md:m-12 md:flex md:flex-row-reverse md:gap-x-12 h-[--mobile-height] xs:h-[--tablet-height] md:h-[--desktop-height]"
      style={
        {
          '--mobile-height': blok.mobile_height + 'px',
          '--tablet-height': blok.tablet_height + 'px',
          '--desktop-height': blok.desktop_height + 'px',
        } as React.CSSProperties
      }
    >
      {/** Subtract half the height of the loan amount card to create overlap effect */}
      <div className="h-[calc(100%-99px)] relative md:h-full md:flex-1">
        <StoryblokPicture
          className="rounded-xl size-full object-cover"
          sizes={`(max-width: ${Number.parseInt(config.theme.screens.md) - 1}px) 100vw, (max-width: 1400px) 50vw, 628px`}
          images={[
            { image: blok.mobile_image },
            { image: blok.tablet_image, minWidth: config.theme.screens.xs },
            {
              image: blok.desktop_image,
              minWidth: config.theme.screens.md,
              scale: 0.5,
            },
          ]}
        />

        <div
          className="absolute inset-0 md:hidden rounded-xl"
          style={
            {
              backgroundImage:
                'linear-gradient(to bottom, var(--gradient-top) 20%, var(--gradient-bottom))',
              '--gradient-bottom':
                blok.mobile_gradient_end?.color || 'rgb(0 0 0 / 0)',
              '--gradient-top':
                blok.mobile_gradient_start?.color || 'rgb(0 0 0 / 0)',
            } as React.CSSProperties
          }
        />
      </div>

      <div className="absolute inset-0 flex flex-col justify-end items-center md:static md:flex-1 md:inset-0 md:justify-center md:items-start">
        <RichTextParagraphStyles className="text-center px-4 md:hidden">
          <StoryblokRichText document={blok.mobile_content} />
        </RichTextParagraphStyles>

        <RichTextParagraphStyles className="hidden md:block">
          <StoryblokRichText document={blok.desktop_content} />
        </RichTextParagraphStyles>

        <LoanAmountCard
          ctaText={blok.button_label}
          className="mt-1 mx-6 md:absolute md:mt-0 md:right-[calc(25%-(320px-1rem+1.5rem)/2)]"
        />
      </div>
    </div>
  )
}
