import { Box } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import AuthDialog from '@/app/auth/components/AuthDialog'
import Success from './../../../../app/common/assets/ico_ilustracao.svg'

const SuccessMessage: FunctionComponent = () => {
  return (
    <AuthDialog
      navBar={{
        title: 'Anfrage erfolgreich',
      }}
      fixedMobileFooter
      content={
        <Box textAlign="center" marginTop="8rem" paddingX="2rem">
          <Success className="inline" />
          <Box fontSize="1.25rem" fontWeight="600" marginTop="1.5rem">
            {' '}
            VIELEN DANK FÜR DEINE ANFRAGE. WIR RUFEN DICH AN!
          </Box>
          <Box marginTop="1.5rem">
            Ein CASHY-Mitarbeiter wird dich demnächst telefonisch kontaktieren.
          </Box>
        </Box>
      }
    />
  )
}
export default SuccessMessage
