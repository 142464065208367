import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { ReviewsReferenceStoryblok } from '@/types/storyblok-component-types'

export default function ReviewsReference({
  blok,
}: {
  blok: ReviewsReferenceStoryblok
}) {
  return typeof blok.reviews !== 'string' && blok.reviews?.content ? (
    <div {...storyblokEditable(blok)}>
      <StoryblokComponent blok={blok.reviews.content} key={blok._uid} />
    </div>
  ) : null
}
