import DefaultLanguageIcon from '../../assets/languageSelector/vector.svg'
import { useRouter } from 'next/router'
import { useId } from 'react'
import { cn } from '@/utils/cn'
import { Language } from './languages'

interface SmallDevicesLanguageSelectorProps {
  selectedLanguage: Language | undefined
  handleLocaleChange: (locale: string) => void
  languages: Language[] | undefined
  isInFooter?: boolean
  footerContentColor: string | undefined
}

export default function SmallDevicesLanguageSelector({
  selectedLanguage,
  handleLocaleChange,
  languages,
  isInFooter,
  footerContentColor,
}: SmallDevicesLanguageSelectorProps) {
  const { locale } = useRouter()
  const id = useId()

  return (
    <div
      className={cn(
        'md:hidden relative flex items-center justify-center px-2',
        isInFooter ? 'w-auto h-auto ml-6' : 'w-10 h-full overflow-hidden',
      )}
    >
      <select
        id={id}
        value={locale}
        onChange={(e) => handleLocaleChange(e.target.value)}
        className="absolute w-full h-full top-0 left-0 opacity-0 cursor-pointer"
      >
        {languages?.map((lang) => (
          <option key={lang.locale} value={lang.locale}>
            {lang.name}
          </option>
        ))}
      </select>
      <label
        htmlFor={id}
        className="flex items-center gap-2 cursor-pointer px-4 py-2 rounded-full"
        style={{
          color: footerContentColor || '#ffffff',
        }}
      >
        {isInFooter ? (
          <>
            <DefaultLanguageIcon className="fill-current" />
            {selectedLanguage?.name}
          </>
        ) : (
          <div className="size-5 overflow-hidden rounded-full inline-block">
            {selectedLanguage && (
              <selectedLanguage.Flag width={20} height={20} />
            )}
          </div>
        )}
      </label>
    </div>
  )
}
