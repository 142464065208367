import { trackDealBooked } from '../../helpers/tracking'
import * as Sentry from '@sentry/nextjs'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { UseFormReturnType } from '@/app/common/components/Form/useForm'
import { useMutation, useQuery } from '@/app/common/graphql/hooks'
import useEnsureCustomerLoggedIn from '@/domains/car/hooks/useEnsureCustomerLoggedIn'
import { getTotalGrossFeeAmountOfType } from '@/helpers/feesCalculation'
import useCompany from '@/helpers/useCompany'
import { EVehicleCategory } from '@/types'
import {
  CreateCustomDealDocument,
  CreateCustomDealMutation,
  CreateCustomDealMutationVariables,
  CustomDealCalculationDocument,
  CustomDealCalculationQuery,
  CustomDealCalculationQueryVariables,
  EDealType,
  ItemAnswerArgs,
  VehicleDataArgs,
  VehiclePropertiesArgs,
} from '@/types/gql/graphql'
import { printLocalAmount } from '@/utils/misc'
import CustomVehicleFormRequest from './CustomVehicleFormRequest'
import { AutoDocumentLinks } from './TermsAndSubmit/TermsAndSubmit'
import { VehicleCalculationBox } from './VehicleCalculationBox/VehicleCalculationBox'
import type {
  CarCalculation,
  CustomVehicleFormData,
  OnAfterDealBookedArgs,
  OnBeforeDealBookedArgs,
} from './VehicleDealBooking'

export interface CustomVehicleRequestData {
  model: string
  make: string
  regdate: string
  odometer: number
  horsePower: string
  fuel: 'petrol' | 'diesel'
  payoutAmount: number
  desiredPayoutAmount: number
  pawnTime: number
  note?: string
  dataPrivacyTermsAccepted: boolean
  fernAbsGAccepted: boolean
  pawnContinueUsing: boolean
}

interface CustomVehicleRequestProps {
  formikProps: UseFormReturnType<CustomVehicleFormData>
  vehicleCategoryId: EVehicleCategory
  baseVehicleCategoryId: EVehicleCategory
  allAnswersUpdatedArgs: ItemAnswerArgs[]
  pawnTimeOptionsInMonths: number[]
  documentLinks: AutoDocumentLinks
  onBeforeDealBooked?: (args: OnBeforeDealBookedArgs) => void | Promise<void>
  onAfterDealBooked?: (args: OnAfterDealBookedArgs) => void | Promise<void>
}

const CustomVehicleRequest: React.FC<CustomVehicleRequestProps> = ({
  formikProps,
  vehicleCategoryId,
  baseVehicleCategoryId,
  allAnswersUpdatedArgs,
  pawnTimeOptionsInMonths,
  documentLinks,
  onBeforeDealBooked,
  onAfterDealBooked,
}) => {
  const { t } = useTranslation()

  const {
    payoutAmount,
    pawnTime,
    make,
    model,
    regdate,
    odometer,
    horsePower,
    fuel,
    note,
  } = formikProps.values

  const [createCustomDeal, createCustomDealResult] = useMutation<
    CreateCustomDealMutation,
    CreateCustomDealMutationVariables
  >(CreateCustomDealDocument)

  const { _id: companyId } = useCompany() || {}
  const customDealCalculationRes = useQuery<
    CustomDealCalculationQuery,
    CustomDealCalculationQueryVariables
  >(CustomDealCalculationDocument, {
    skip: !payoutAmount || !formikProps.isValid || !companyId,
    variables: {
      customDealCalculationArgs: {
        isReversedFeeCalculation: true,
        companyId,
        durationInDays: pawnTime * 30,
        calculationItems: [
          {
            payoutAmount,
            itemCategoryId: vehicleCategoryId,
            answers: allAnswersUpdatedArgs,
          },
        ],
      },
    },
  })

  const customRequestCalculation =
    customDealCalculationRes.data?.customDealCalculation

  const calculation = (
    customRequestCalculation &&
    !customDealCalculationRes.isFetching &&
    formikProps.isValid
      ? {
          calculation: customRequestCalculation,
          payoutAmount: customRequestCalculation.payoutAmount,
          paybackAmount:
            customRequestCalculation.payoutAmount +
            getTotalGrossFeeAmountOfType(
              customRequestCalculation.appliedUsedFeeDefinitions,
            ),
          feesCaclulation: getTotalGrossFeeAmountOfType(
            customRequestCalculation.appliedUsedFeeDefinitions,
          ),
          appliedUsedFeeDefinitions:
            customRequestCalculation.appliedUsedFeeDefinitions,
          dealItems: [
            {
              title: [make, model, regdate].join(' '),
            },
          ],
        }
      : undefined
  ) as CarCalculation | undefined

  const ensureCustomerLoggedIn = useEnsureCustomerLoggedIn()
  const _onSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    // validate form first
    if (!formikProps.isValid) return

    // formik validation is passed
    const customVehicleRequestData =
      formikProps.values as CustomVehicleRequestData

    const definedVehicleProperties: Partial<VehiclePropertiesArgs> & any = {
      odometer,
    }

    if (!companyId) {
      console.warn('Company not loaded.')
      return
    }

    if (!customRequestCalculation || customDealCalculationRes.isFetching) return

    const vehicleData: VehicleDataArgs = {
      vehicleProperties: {
        make,
        model,
        odometer,
        regdate,
        fuel,
        horsePower: horsePower + '',
      },
    }

    const trackingType =
      baseVehicleCategoryId === EVehicleCategory.CAR ? 'CarCR' : 'MotorcycleCR'
    if (onBeforeDealBooked) {
      try {
        await onBeforeDealBooked({
          customVehicleRequestData,
          definedVehicleProperties,
          trackingType,
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    const customerId = await ensureCustomerLoggedIn({
      headline: t('vehicle:signup_modal.headline', {
        amount: printLocalAmount({ number: payoutAmount, fractionDigits: 0 }),
      }),
      barTitle: t('vehicle:signup_modal.barTitle'),
      description: t('vehicle:signup_modal.description', {
        vehicleName:
          baseVehicleCategoryId === EVehicleCategory.CAR
            ? 'Autos'
            : 'Motorrades',
      }),
    })

    if (customerId === null) {
      return
    }

    const res = await createCustomDeal({
      variables: {
        customDealCreateArgs: {
          companyId: companyId,
          contactData: {
            email: '',
            phone: '',
          },
          customerId,
          durationInDays: pawnTime * 30,
          items: [
            {
              itemCategoryId: vehicleCategoryId,
              title:
                vehicleData?.vehicleProperties.make ??
                '' + ' ' + vehicleData?.vehicleProperties.model ??
                '',
              vehicleData,
              pawnPayoutAmount: payoutAmount,
              purchasePayoutAmount: payoutAmount,
              answers: allAnswersUpdatedArgs,
              note: note ?? '',
            },
          ],
          date: customRequestCalculation.date,
          dealType: EDealType.Pawn,
        },
      },
    })

    if (res.data) {
      try {
        trackDealBooked({
          dealId: res.data.createCustomDeal._id,
          type: 'pawn',
          items: res.data.createCustomDeal.items.map((i) => ({
            categoryHierarchy: i.itemCategory.parentCategories.map(
              (c) => c._id,
            ),
          })),
        })
      } catch (error) {
        Sentry.captureException(error)
      }

      if (onAfterDealBooked) {
        const dealId = res.data.createCustomDeal._id
        await onAfterDealBooked({
          _id: dealId,
          bookingNumber: dealId,
          customVehicleRequestData,
          definedVehicleProperties,
          trackingType,
        })
      }
    }
  }

  const odometerError =
    formikProps.errors.odometer && !formikProps.errors.odometer?.includes('NaN')

  return (
    <>
      <CustomVehicleFormRequest formikProps={formikProps} />
      {companyId && odometer && horsePower && !odometerError ? (
        <VehicleCalculationBox
          calculation={calculation}
          calculationLoading={customDealCalculationRes.isFetching}
          marketValueToSmall={false}
          pawnTimeOptionsInMonths={pawnTimeOptionsInMonths}
          baseVehicleCategoryId={baseVehicleCategoryId}
          createDealResult={createCustomDealResult}
          isCustomRequest={true}
          formikProps={formikProps}
          documentLinks={documentLinks}
          onSubmit={_onSubmit}
        />
      ) : null}
    </>
  )
}

export default CustomVehicleRequest
