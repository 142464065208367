import { StoryblokComponent } from '@storyblok/react'
import { useImperativeHandle, useLayoutEffect, useState } from 'react'
import { useRef } from 'react'
import { forwardRef } from 'react'
import { useThrottledCallback } from '@/helpers/useThrottledCallback'
import { ReviewsV2Storyblok } from '@/types/storyblok-component-types'
import { cn } from '@/utils/cn'

export interface CarouselRef {
  scrollToIndex: (index: number) => void
}

interface CarouselProps {
  items: ReviewsV2Storyblok['items']
  onIndexChange: (index: number) => void
  itemWidth: number
  itemGap: number
  className?: string
}

export const Carousel = forwardRef(
  (
    { items, onIndexChange, itemWidth, itemGap, className }: CarouselProps,
    ref,
  ) => {
    const carouselRef = useRef<HTMLDivElement | null>(null)
    const [edgePadding, setEdgePadding] = useState<number | null>(null)

    // We want to make sure that the first (and last, but that's less important) item is in the center of the carousel.
    // For that, we need to calculate the dead center of the carousel, e.g. the middle of the
    // carousel has to be exactly in the middle of the first card.
    // While doing that, we need to ensure that the `scrollLeft` property of the carousel
    // is initially 0, so that that our index calculations work reliably. Inserting a
    // zero height padding element with just the exact width seemed the easiest way to achieve that.
    useLayoutEffect(() => {
      if (carouselRef.current) {
        const centerPoint = carouselRef.current.clientWidth / 2
        const scrollLeft = itemWidth + itemGap + itemWidth / 2 - centerPoint
        setEdgePadding(itemWidth - scrollLeft)
      }
    }, [itemGap, itemWidth])

    const [handleScroll] = useThrottledCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement
        const newIndex = getIndexForScrollLeft(
          target.scrollLeft,
          itemWidth,
          itemGap,
        )
        onIndexChange(newIndex)
      },
      50,
      [itemGap, itemWidth, onIndexChange],
    )

    useImperativeHandle(
      ref,
      (): CarouselRef => ({
        scrollToIndex: (index: number) => {
          carouselRef.current?.scrollTo({
            left: index * (itemWidth + itemGap),
            behavior: 'smooth',
          })
        },
      }),
      [itemGap, itemWidth],
    )

    return (
      <div
        ref={carouselRef}
        onScroll={handleScroll}
        className={cn(
          'flex flex-row items-center snap-x snap-mandatory overflow-x-scroll scroll-smooth no-scrollbar',
          className,
        )}
        style={{
          gap: itemGap,
        }}
      >
        {[null, ...items, null].map((item, index) => (
          <div
            className={cn(
              'snap-center snap-always',
              // This prevents a layout shift before the edge padding is calculated.
              edgePadding == null && 'opacity-0',
            )}
            key={item?._uid ?? index}
          >
            {item ? (
              <div style={{ width: itemWidth }}>
                <StoryblokComponent blok={item} />
              </div>
            ) : (
              <div
                style={{
                  width: edgePadding ?? 0,
                  // Safari needs a height, otherwise the rightmost element won't get rendered
                  height: 1,
                }}
              />
            )}
          </div>
        ))}
      </div>
    )
  },
)

function getIndexForScrollLeft(
  scrollLeft: number,
  itemWidth: number,
  itemGap: number,
) {
  return Math.round(Math.max(scrollLeft, 0) / (itemWidth + itemGap))
}
