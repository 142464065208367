import { atom } from 'recoil'
import {
  TransportFormState,
  TransportFormStateStorebox,
} from '@/app/common/common.state'
import { KnpDebt, SelectedItemsType } from '@/types'
import {
  EDealType,
  ETransportMode,
  GetShopsForCategoriesQuery,
  PaymentDataArgs,
  PersistedDealCalculation,
  TransportData,
} from '@/types/gql/graphql'

export const checkoutDateState = atom<Date | undefined>({
  key: 'checkout:date', // unique ID (with respect to other atoms/selectors)
  default: undefined, // default value (aka initial value)
})

export const selectedItemsState = atom<SelectedItemsType[]>({
  key: 'checkout:selectedItems',
  default: [],
})

export const dealCalculationState = atom<PersistedDealCalculation | undefined>({
  key: 'checkout:dealCalculation',
  default: undefined,
})

export const dealTypeState = atom<EDealType>({
  key: 'checkout:dealType',
  default: EDealType.Pawn,
})

export const isSimplifiedPawnCheckoutState = atom<boolean>({
  key: 'checkout:isSimplifiedPawnCheckout',
  default: true,
})

export const dealDurationInDaysState = atom<number | undefined>({
  key: 'checkout:dealDurationInDaysState',
  default: undefined,
})

export const transportDataState = atom<TransportData | undefined>({
  key: 'checkout:transportData',
  default: undefined,
})

export const paymentDataState = atom<PaymentDataArgs | undefined>({
  key: 'checkout:paymentData',
  default: undefined,
})

export const dealIdState = atom({
  key: 'checkout:dealId',
  default: '',
})

export const dealBookingNumberState = atom<number | undefined>({
  key: 'checkout:dealBookingNumber',
  default: undefined,
})

export const customDealIdState = atom<string | undefined>({
  key: 'checkout:customDealId',
  default: '',
})

export const customDealAvailableTransportModesState = atom<ETransportMode[]>({
  key: 'checkout:customDealAvailableTransportModesState',
  default: [],
})

export const feedbackState = atom({
  key: 'checkout:feedback', // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
})

export const knpDebtState = atom<KnpDebt | undefined>({
  key: 'chcekout:knpDebt',
  default: undefined,
})

export const feedbackSentenceState = atom<
  | {
      paymentData: PaymentDataArgs
      transportData?: TransportData
      payoutAmount: number
      durationInDays?: number
      email: string
      availableShops?: GetShopsForCategoriesQuery['getShopsForCategories']
    }
  | undefined
>({
  key: 'checkout:feedbackSentence',
  default: undefined,
})

export const transportFormStateCheckout = atom<
  TransportFormState | TransportFormStateStorebox | undefined
>({
  key: 'checkout:transportForm',
  default: undefined,
})

export const isCustomDealPayoutOverwrittenState = atom<boolean | undefined>({
  key: 'checkout:isCustomDealPayoutOverwritten',
  default: undefined,
})

export const guestEmailState = atom<string>({
  key: 'checkout:guestEmail',
  default: '',
})
