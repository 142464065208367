import React from 'react'
import { cn } from '@/utils/cn'
import Star from './assets/star.svg'

interface Props {
  rating: number
  className?: string
}

export function StarsRating({ rating, className }: Props) {
  return (
    <div className={cn('flex flex-row', className)}>
      {Array.from({ length: Math.round(rating) }, (_, i) => (
        <Star className="size-4" key={i} />
      ))}
    </div>
  )
}
