import { storyblokEditable } from '@storyblok/react'
import Link from 'next/link'
import IconSearch from '@/icons/24/magnifying-glass.svg'
import { SearchBarStoryblok } from '@/types/storyblok-component-types'

interface Props {
  blok: SearchBarStoryblok
}

export default function SearchBar(props: Props) {
  const {
    placeholder,
    placeholderColor,
    backgroundColor,
    borderColor,
    maxWidth,
  } = props.blok
  return (
    <Link
      {...storyblokEditable(props.blok)}
      href="/search"
      style={{
        maxWidth: `${maxWidth}px`,
        backgroundColor: backgroundColor.color || '#FFFFFF',
        ...(borderColor?.color && {
          border: `2px solid ${borderColor.color}`,
        }),
      }}
      className="inline-flex items-center p-1 rounded-md w-full"
    >
      <div className="p-1 text-black">
        <IconSearch className="size-5" />
      </div>
      <span
        className="py-2 px-1 flex-grow text-sm cursor-text inline-flex justify-start"
        style={{
          color: placeholderColor?.color || '#6C6D6F',
        }}
      >
        {placeholder}
      </span>
    </Link>
  )
}
