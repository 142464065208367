import Link from 'next/link'
import { cn } from '@/utils/cn'

interface VariantProps {
  weight?: 'regular' | 'medium' | 'semibold' | 'bold'
  as?: 'a' | 'next-link'
}

interface UnderlinedLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps {
  href: string
}

export function UnderlinedLink({
  className,
  weight,
  as = 'a',
  ...props
}: UnderlinedLinkProps) {
  const Comp = as === 'a' ? 'a' : Link
  return (
    <Comp
      className={cn(underlineLinkStyles({ weight }), className)}
      {...props}
    />
  )
}

export function underlineLinkStyles({ weight }: VariantProps) {
  return cn(
    'text-inherit underline decoration-accent-500 underline-offset-[3px] relative',
    weight === 'medium' && 'font-medium',
    weight === 'semibold' && 'font-semibold',
    weight === 'bold' && 'font-bold',
  )
}
