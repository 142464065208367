import Link from 'next/link'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { UrlObject } from 'url'
import { underlineLinkStyles } from '@/components/UnderlinedLink'
import Text from '@/style/components/LegacyText'
import { cn } from '@/utils/cn'
import { ImageWrapper, Wrapper } from './ObjectListItemStyles'
import Background from './assets/bkg_logo.svg'

interface ObjectListFormItemProps {
  title: string
  prompt: string
  href: UrlObject | string
}

const StyledWrapper = styled(Wrapper)`
  border: 2px solid ${({ theme }) => theme.colors.border};
`

const StyledImageWrapper = styled(ImageWrapper)`
  padding-top: 83%;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
  }
`

const StyledBackground = styled(Background)`
  position: absolute;
  top: 22.5%;
  left: 25%;
  height: auto;
  width: 50%;
  * {
    fill: ${({ theme }) => theme.colors.border};
  }
`

// TODO: Add ProductJsonLd from next-seo.

const ObjectListFormItem: FunctionComponent<ObjectListFormItemProps> = ({
  title,
  href,
  prompt,
}) => {
  return (
    <Link href={href} passHref legacyBehavior>
      <StyledWrapper className="bg-fill-senary">
        <StyledBackground />
        <StyledImageWrapper>
          <Text.ms weight="semibold">{title}</Text.ms>
        </StyledImageWrapper>
        <p
          className={cn(underlineLinkStyles({ weight: 'semibold' }), 'text-sm')}
        >
          {prompt}
        </p>
      </StyledWrapper>
    </Link>
  )
}

export default ObjectListFormItem
