import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import React, { useContext } from 'react'
import { useLogoutCustomer } from '@/app/auth/auth.service'
import { customerState } from '@/app/auth/auth.state'
import Login from '@/app/auth/components/Login'
import Register from '@/app/auth/components/Register'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { useRecoilStateWithSSRFallback } from '@/app/common/recoil'
import { Button } from '@/components/Button'

interface MenuFooterProps {
  closeMenu: () => void
}

export function MenuFooter({ closeMenu }: MenuFooterProps) {
  const { t } = useTranslation()
  const modal = useContext(ModalDialogContext)
  const [user] = useRecoilStateWithSSRFallback(customerState, undefined)
  const logoutCustomer = useLogoutCustomer()

  if (user) {
    return (
      <>
        <Button variant="secondary" asChild>
          <Link href="/profile/edit">{t('common:auth.account_settings')}</Link>
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            logoutCustomer()
            closeMenu()
          }}
        >
          Logout
        </Button>
      </>
    )
  }

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          closeMenu()
          modal.open(<Login />, { variant: 'full' })
        }}
      >
        {t('common:auth.login')}
      </Button>
      <Button
        onClick={() => {
          closeMenu()
          modal.open(<Register />, { variant: 'full' })
        }}
      >
        {t('common:auth.register')}
      </Button>
    </>
  )
}
