/* eslint-disable jsx-a11y/anchor-is-valid */
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import useTranslation from 'next-translate/useTranslation'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStoryblokConfig } from '@/app/common/context/storyblokConfigContext'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { CookiesAccordionItem, IAccordionItem } from './Accordion'

interface IndividualSelectionsScreenProps {
  setIndividualOptions: React.Dispatch<React.SetStateAction<boolean[]>>
  individualOptions: boolean[]
  onCancel: () => void
}

const IndividualSelectionsScreen = (props: IndividualSelectionsScreenProps) => {
  const { individualOptions, setIndividualOptions } = props
  const { t } = useTranslation('common')
  const [isOpened, setOpened] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const { config } = useStoryblokConfig()

  const data: IAccordionItem[] = [
    {
      title: t('cookies.necessary_cookie.title'),
      description: t('cookies.necessary_cookie.description'),
      purpose: t('cookies.necessary_cookie.purpose'),
      mutable: false,
    },
    {
      title: t('cookies.google_analytics.title'),
      description: t('cookies.google_analytics.description'),
      purpose: t('cookies.google_analytics.purpose'),
      mutable: true,
    },
    {
      title: t('cookies.microsoft_clarity.title'),
      description: t('cookies.microsoft_clarity.description'),
      purpose: t('cookies.microsoft_clarity.purpose'),
      mutable: true,
    },
    {
      title: t('cookies.google_ads.title'),
      description: t('cookies.google_ads.description'),
      purpose: t('cookies.google_ads.purpose'),
      mutable: true,
    },
    {
      title: t('cookies.microsoft_ads.title'),
      description: t('cookies.microsoft_ads.description'),
      purpose: t('cookies.microsoft_ads.purpose'),
      mutable: true,
    },
    {
      title: t('cookies.meta_pixel.title'),
      description: t('cookies.meta_pixel.description'),
      purpose: t('cookies.meta_pixel.purpose'),
      mutable: true,
    },
    {
      title: t('cookies.tiktok_pixel.title'),
      description: t('cookies.tiktok_pixel.description'),
      purpose: t('cookies.tiktok_pixel.purpose'),
      mutable: true,
    },
  ]

  const toggleOpenByIndex = (index: number) => {
    isOpened[index] = !isOpened[index]
    setOpened((o) => o.map((v, i) => (i === index ? !v : v)))
  }

  const toggleCheckedByIndex = (index: number) => {
    setIndividualOptions((io) => io.map((v, i) => (i === index ? !v : v)))
  }

  return (
    <>
      <ModalHeader onClick={props.onCancel}>
        <ModalHeaderIcon>
          <ArrowBackIos />
        </ModalHeaderIcon>
        <ModalHeaderText>{t('cookies.intro_header_granular')}</ModalHeaderText>
      </ModalHeader>
      <ModalDescription>
        <p>{t('cookies.intro_text')}</p>
        <p>
          {t('cookies.more_info')}{' '}
          <a
            href={getStoryBlokLink(config.privacy_policy)}
            target="_blank"
          >{`${t('cookies.protection')}`}</a>
          ,{' '}
          <a href={'/impressum'} target="_blank">{`${t('cookies.imprint')}`}</a>
          .
        </p>
      </ModalDescription>
      <AccordionsContainer>
        {data.map((item, index) => (
          <CookiesAccordionItem
            key={index}
            index={index}
            isExpanded={isOpened[index]}
            onChange={() => toggleOpenByIndex(index)}
            data={item}
            toggleChecked={() => toggleCheckedByIndex(index)}
            isChecked={individualOptions[index]}
            mutable={item.mutable}
          />
        ))}
      </AccordionsContainer>
    </>
  )
}

export default IndividualSelectionsScreen

const ModalHeader = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
`

const ModalHeaderIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 0.25rem;

  svg {
    width: 100%;
    height: 100%;
  }
`

const ModalHeaderText = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
`

const ModalDescription = styled.h6`
  font-size: 0.75rem;
  line-height: 0.875rem;

  > p:first-child {
    margin-bottom: 0.5rem;
  }

  a {
    font-weight: 700;
    text-decoration: underline;
    color: #000;
  }
`

const AccordionsContainer = styled.div`
  width: 100%;
  margin-top: 0.75rem;
`
