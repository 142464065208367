import React from 'react'
import styled from 'styled-components'
import { Button } from '@/style/components/Button'
import { cn } from '@/utils/cn'

export interface OptionsProps {
  value: string | number | boolean
  text: string
}

interface ConfiguratorPropertyProps {
  headline: string
  description: string | undefined
  value?: any
  options: OptionsProps[]
  onChange?: (value: any) => void
}

const ConfiguratorProperty = ({
  headline,
  description,
  value,
  options,
  onChange,
}: ConfiguratorPropertyProps) => {
  return (
    <GroupWrapper>
      <h3 className="text-heading-2xs font-semibold text-secondary uppercase mb-2">
        {headline}
      </h3>

      {description && (
        <span className="text-body-sm text-tertiary mb-2">{description}</span>
      )}

      {options.length > 0 && (
        <div className="flex flex-row gap-2 justify-center flex-wrap">
          {(options ?? []).map((option) => (
            <Button
              type="button"
              key={option.value.toString()}
              className={cn(
                'm-1 !border !rounded-xl',
                option.value == value &&
                  '!text-black !bg-fill-brand-tertiary !border-brand',
              )}
              appearance={option.value == value ? 'primary-outline' : 'whitebg'}
              onClick={() => {
                if (onChange) {
                  value = option.value
                  onChange(option.value)
                }
              }}
            >
              {option.text}
            </Button>
          ))}
        </div>
      )}
    </GroupWrapper>
  )
}

const GroupWrapper = styled.section`
  text-align: center;
  margin: 3rem 0;

  div {
    padding-bottom: 1rem;
  }
`

export default ConfiguratorProperty
