import { storyblokEditable } from '@storyblok/react'
import Link from 'next/link'
import StoryblokRichText from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { StarsRating } from '@/blocks/ReviewsV2/StarsRating'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { ReviewsItemV2Storyblok } from '@/types/storyblok-component-types'

export default function ReviewsItem({
  blok,
}: {
  blok: ReviewsItemV2Storyblok
}) {
  return (
    <div
      className="flex flex-col rounded-xl p-6 bg-fill-senary"
      {...storyblokEditable(blok)}
    >
      <p className="font-semibold text-heading-s">{blok.username}</p>
      <StarsRating className="mb-4" rating={Number(blok.rating)} />

      <StoryblokRichText document={blok.text} />

      {blok.link && blok.link_text && (
        <Link
          className="text-center text-brand-secondary mt-4 font-semibold text-l"
          href={getStoryBlokLink(blok.link)}
        >
          {blok.link_text}
        </Link>
      )}
    </div>
  )
}
