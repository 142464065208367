import Link from 'next/link'
import SearchIcon from '@/icons/24/magnifying-glass.svg'

export function SearchButton() {
  return (
    <Link
      className="h-full flex items-center w-10 justify-center"
      href="/search"
    >
      <SearchIcon />
    </Link>
  )
}
