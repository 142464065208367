import { captureException } from '@sentry/nextjs'
import { sum } from 'lodash'
import invariant from 'tiny-invariant'

type DealType = 'pawn' | 'purchase' | 'commission'
export interface DealMarginEstimationInput {
  type: DealType
  items: CustomDealItem[] | DatabaseItem[]
}

export interface CustomDealItem {
  categoryHierarchy: string[]
}

export interface DatabaseItem extends CustomDealItem {
  payout: number | null | undefined
}

export default function estimateDealMargin({
  type,
  items,
}: DealMarginEstimationInput): number {
  if (
    type !== 'commission' &&
    items.some((i) => CommissionCategories.includes(i.categoryHierarchy[0]))
  ) {
    type = 'commission'
  }

  const itemMargins = items.map((i) => estimateMargin(i, type))

  // these deals often are booked incorrectly (to few items, wrong weights etc.)
  // so instead for affected categories we store average deal values and use
  // the highest one
  if (
    items.some(
      (item) =>
        item.categoryHierarchy.includes(CategoryId.Jewelry) ||
        item.categoryHierarchy.includes(CategoryId.CoinsAndBars),
    )
  ) {
    return Math.max(...itemMargins)
  }

  return sum(itemMargins)
}

function estimateMargin(
  item: CustomDealItem | DatabaseItem,
  type: DealType,
): number {
  try {
    const isDatabaseItem = 'payout' in item
    const payout =
      isDatabaseItem && typeof item.payout === 'number'
        ? item.payout
        : getBestCategoryPathMatch(
            item.categoryHierarchy,
            averagePayoutByCategoryId,
          )
    invariant(payout !== undefined, 'Missing average payout for category path')

    const acceptanceRate =
      (isDatabaseItem
        ? undefined
        : getBestCategoryPathMatch(
            item.categoryHierarchy,
            customDealVerificationRateByCategoryId,
          )) ??
      getBestCategoryPathMatch(
        item.categoryHierarchy,
        dealVerificationRateByCategoryId,
      )
    invariant(
      acceptanceRate !== undefined,
      'Missing acceptance rate for category path',
    )

    const payoutExpectancyValue = payout * acceptanceRate
    if (type === 'purchase') {
      return payoutExpectancyValue * 0.04
    } else if (type === 'commission') {
      return payoutExpectancyValue * 1.5 * 0.14
    }

    const averageDuration = getBestCategoryPathMatch(
      item.categoryHierarchy,
      averageDurationByCategoryId,
    )
    invariant(
      averageDuration !== undefined,
      'Missing average duration for category path',
    )

    return payoutExpectancyValue * averageDuration * 0.06
  } catch (error) {
    console.error('categories', item.categoryHierarchy, error)
    captureException(new Error('Margin estimation failed'))

    return 0
  }
}

function isCategoryId(id: string): id is CategoryId {
  return Object.values(CategoryId).includes(id as CategoryId)
}

export enum CategoryId {
  Electronics = '612e510d880278dd21d87dd9',
  Smartphones = '612e510d880278dd21d87de8',
  AppleSmartphones = '612e510f880278dd21d87e2b',
  Jewelry = '612e510d880278dd21d87ddb',
  Consoles = '612e510d880278dd21d87dea',
  SonyConsoles = '612e510f880278dd21d87e34',
  SamsungSmartphones = '612e510f880278dd21d87e2d',
  Cars = '612e510d880278dd21d87ddf',
  Tablets = '612e510d880278dd21d87dec',
  Computers = '612e510e880278dd21d87e04',
  MiscJewelry = '612e510d880278dd21d87df9',
  AppleTablets = '612e510f880278dd21d87e30',
  AppleComputers = '631778b361036a7ead08b9d9',
  Rings = '612e510d880278dd21d87ded',
  Watches = '612e510d880278dd21d87ddc',
  Smartwatches = '612e510d880278dd21d87dee',
  AppleSmartwatches = '612e510f880278dd21d87e33',
  CoinsAndBars = '612e510d880278dd21d87dde',
  NintendoConsoles = '612e510f880278dd21d87e35',
  Coins = '612e510d880278dd21d87df6',
  CollectorAndInvestorCoins = '612e510f880278dd21d87e46',
  Bags = '612e510d880278dd21d87ddd',
  Necklaces = '612e510d880278dd21d87deb',
  Cameras = '612e510d880278dd21d87de3',
  RolexWatches = '631778b361036a7ead08baf7',
  Misc = '612e510d880278dd21d87dda',
  SamsungTablets = '612e510f880278dd21d87e31',
  MicrosoftConsoles = '612e510f880278dd21d87e29',
  Bracelets = '612e510d880278dd21d87dfb',
  Ebikes = '63da1af8b75a420e2f177c24',
  XiaomiSmartphones = '612e510f880278dd21d87e39',
  PendantsAndAccessories = '612e510d880278dd21d87df8',
  LouisVuittonBags = '612e510d880278dd21d87df7',
  GoldBars = '612e510d880278dd21d87df5',
  Earrings = '612e510d880278dd21d87df1',
  LenovoComputers = '631778b361036a7ead08b9e4',
  Tools = '612e510d880278dd21d87de2',
  SonyCameras = '631778b361036a7ead08baad',
  HPComputers = '631778b361036a7ead08b9e2',
  BreitlingWatches = '631778b361036a7ead08bac9',
  CarsStored = '618b9219697fcfab9a619b1b',
  Appliances = '612e510d880278dd21d87de4',
  LifeInsurance = '612e5234d3a8d0ddeb428102',
  Motorcycles = '616e8d5bf99947cba49f492b',
  MotorcyclesStored = '618b93f24466a86c06e73a7e',
  Drones = '631778b361036a7ead08b9cc',
  CommissionWatch = '66ebd1c0a9980a00087d5491',
  CommissionMisc = '66f533acb8dbec0008689480',
  CommissionBag = '66ebd1efa9980a00087d5493',
  CommissionCar = '66ebd1fca9980a00087d5495',
}

const CommissionCategories: string[] = [
  CategoryId.CommissionWatch,
  CategoryId.CommissionMisc,
  CategoryId.CommissionBag,
  CategoryId.CommissionCar,
]

const dealVerificationRateByCategoryId: Partial<Record<CategoryId, number>> = {
  [CategoryId.Electronics]: 0.59,
  [CategoryId.Smartphones]: 0.55,
  [CategoryId.Smartwatches]: 0.57,
  [CategoryId.Consoles]: 0.68,
  [CategoryId.Jewelry]: 0.78,
  [CategoryId.MiscJewelry]: 0.74,
  [CategoryId.Bracelets]: 0.7,
  [CategoryId.Rings]: 0.84,
  [CategoryId.CoinsAndBars]: 0.33,
  [CategoryId.PendantsAndAccessories]: 0.84,
  [CategoryId.GoldBars]: 0.12,
  [CategoryId.Cars]: 0,
  [CategoryId.CarsStored]: 0,
}

const customDealVerificationRateByCategoryId: Partial<
  Record<CategoryId, number>
> = {
  [CategoryId.Electronics]: 0.1,
  [CategoryId.Computers]: 0.17,
  [CategoryId.AppleComputers]: 0.28,
  [CategoryId.Bags]: 0.13,
  [CategoryId.Watches]: 0.25,
  [CategoryId.Tools]: 0.016,
  [CategoryId.Misc]: 0.015,
  [CategoryId.Ebikes]: 0.14,
  [CategoryId.Cameras]: 0.15,
  // for some reason verification rate dropped to near-zero since
  // we have DB supported smartphone custom deals
  [CategoryId.Smartphones]: 0.03,
  [CategoryId.Appliances]: 0.025,
  [CategoryId.LifeInsurance]: 0.023,
  // same as for smartphones
  [CategoryId.Tablets]: 0.1,
  [CategoryId.LouisVuittonBags]: 0.21,
  [CategoryId.RolexWatches]: 0.39,
  [CategoryId.Jewelry]: 0.4,
  [CategoryId.CommissionMisc]: 0.01,
  [CategoryId.CommissionBag]: 0.013,
  [CategoryId.CommissionWatch]: 0.08,
  [CategoryId.Motorcycles]: 0,
  [CategoryId.MotorcyclesStored]: 0,
}

const averageDurationByCategoryId: Partial<Record<CategoryId, number>> = {
  [CategoryId.Electronics]: 2.74,
  [CategoryId.Smartphones]: 2.38,
  [CategoryId.Jewelry]: 3.5,
  [CategoryId.Consoles]: 2.74,
  [CategoryId.SonyConsoles]: 2.68,
  [CategoryId.SamsungSmartphones]: 2.31,
  [CategoryId.Cars]: 8.6,
  [CategoryId.Tablets]: 3.35,
  [CategoryId.Computers]: 3.61,
  [CategoryId.MiscJewelry]: 3.64,
  [CategoryId.AppleTablets]: 3.5,
  [CategoryId.AppleComputers]: 3.65,
  [CategoryId.Rings]: 3.18,
  [CategoryId.Watches]: 5,
  [CategoryId.Smartwatches]: 2.84,
  [CategoryId.AppleSmartwatches]: 2.81,
  [CategoryId.CoinsAndBars]: 2.51,
  [CategoryId.NintendoConsoles]: 2.91,
  [CategoryId.Coins]: 2.43,
  [CategoryId.CollectorAndInvestorCoins]: 2.46,
  [CategoryId.Bags]: 5.16,
  [CategoryId.Necklaces]: 3.8,
  [CategoryId.Cameras]: 3.42,
  [CategoryId.RolexWatches]: 4.51,
  [CategoryId.Misc]: 9.55,
  [CategoryId.SamsungTablets]: 2.93,
  [CategoryId.MicrosoftConsoles]: 2.78,
  [CategoryId.Bracelets]: 3.84,
  [CategoryId.Ebikes]: 4.46,
  [CategoryId.XiaomiSmartphones]: 2.02,
  [CategoryId.PendantsAndAccessories]: 3.25,
  [CategoryId.LouisVuittonBags]: 5.02,
  [CategoryId.GoldBars]: 2.79,
  [CategoryId.Earrings]: 3.36,
  [CategoryId.LenovoComputers]: 3.59,
  [CategoryId.Tools]: 2.94,
  [CategoryId.SonyCameras]: 3.51,
  [CategoryId.HPComputers]: 3.07,
  [CategoryId.BreitlingWatches]: 4.25,
  [CategoryId.CarsStored]: 5.53,
  [CategoryId.Appliances]: 3.49,
  [CategoryId.LifeInsurance]: 8.91,
  [CategoryId.Motorcycles]: 7.1,
  [CategoryId.MotorcyclesStored]: 4.15,
  [CategoryId.Drones]: 2.77,
}

const averagePayoutByCategoryId: Partial<Record<CategoryId, number>> = {
  [CategoryId.Electronics]: 359,
  [CategoryId.Smartphones]: 260, // stats say 418 but influenced by apple
  [CategoryId.AppleSmartphones]: 479,
  [CategoryId.Jewelry]: 488, // from deals
  [CategoryId.Consoles]: 172,
  [CategoryId.SonyConsoles]: 198,
  [CategoryId.SamsungSmartphones]: 291,
  [CategoryId.Cars]: 3500,
  [CategoryId.CarsStored]: 11831,
  [CategoryId.Tablets]: 296,
  [CategoryId.Computers]: 580,
  [CategoryId.MiscJewelry]: 712,
  [CategoryId.AppleTablets]: 315,
  [CategoryId.AppleComputers]: 583,
  [CategoryId.Rings]: 253, // from deals
  [CategoryId.Watches]: 6347,
  [CategoryId.Smartwatches]: 224,
  [CategoryId.AppleSmartwatches]: 235,
  [CategoryId.CoinsAndBars]: 1286, // from deals
  [CategoryId.NintendoConsoles]: 100,
  [CategoryId.Coins]: 1306, // from deals
  [CategoryId.CollectorAndInvestorCoins]: 371,
  [CategoryId.Bags]: 689,
  [CategoryId.Necklaces]: 406,
  [CategoryId.Cameras]: 642,
  [CategoryId.RolexWatches]: 6880,
  [CategoryId.Misc]: 200,
  [CategoryId.SamsungTablets]: 215,
  [CategoryId.MicrosoftConsoles]: 146,
  [CategoryId.Bracelets]: 628, // from deals
  [CategoryId.Ebikes]: 1085,
  [CategoryId.XiaomiSmartphones]: 192,
  [CategoryId.PendantsAndAccessories]: 289,
  [CategoryId.LouisVuittonBags]: 637,
  [CategoryId.GoldBars]: 1206, // from deals
  [CategoryId.Earrings]: 198, // from deals
  [CategoryId.Tools]: 232,
  [CategoryId.Appliances]: 396,
  [CategoryId.LifeInsurance]: 3028,
  [CategoryId.Motorcycles]: 2952,
  [CategoryId.MotorcyclesStored]: 4520,
  [CategoryId.Drones]: 332,
  [CategoryId.CommissionWatch]: 2292,
  [CategoryId.CommissionMisc]: 1250,
  [CategoryId.CommissionBag]: 241,
  [CategoryId.CommissionCar]: 0, // never verified yet
}

function getBestCategoryPathMatch(
  categoryPath: string[],
  valuesMap: Partial<Record<CategoryId, number>>,
): number | undefined {
  for (const categoryId of [...categoryPath].reverse()) {
    if (!isCategoryId(categoryId)) {
      continue
    }

    const value = valuesMap[categoryId]
    if (value !== undefined) {
      return value
    }
  }
}
