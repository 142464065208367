interface QuestionMarkProps {
  id?: string
  onClick: () => void
}

export function QuestionMark(props: QuestionMarkProps) {
  return (
    <span className="inline-block">
      <button
        className="bg-fill-quaternary text-static-white text-[0.82rem] md:text-[1rem] font-medium rounded-full size-[1.2rem] leading-[1.2rem] ml-2 pl-[0.4rem] tracking-[0.4rem]"
        id={props.id}
        onClick={props.onClick}
      >
        <div className="-ml-px">?</div>
      </button>
    </span>
  )
}
