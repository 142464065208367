import { Button } from '@/components/Button'
import { LeftArrow, RightArrow } from '@/style/components/Arrow/Arrow'
import { cn } from '@/utils/cn'

interface AuthDialogProps {
  content: JSX.Element
  sideIcon?: JSX.Element
  fixedMobileFooter?: boolean
  navBar: {
    title: string
    link?: {
      description: string
      text: string
      onClick: () => void
    }
    backButton?: () => void
  }
}

export function AuthDialog({
  content,
  navBar,
  sideIcon,
  fixedMobileFooter,
}: AuthDialogProps) {
  return (
    <div className="w-full h-[calc(100%-110px)] sm:w-[700px] sm:h-[698px] sm:mt-0 sm:rounded-xl md:w-[860px] md:h-[698px] md:mt-0 md:rounded-xl">
      <div className="flex flex-row flex-initial flex-wrap box-border h-full content-stretch m-0">
        <div className="w-full text-center bg-white rounded-xl sm:pt-4 sm:h-12 md:hidden">
          {navBar.backButton && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <span
              className="absolute top-[14px] left-[20px] cursor-pointer z-40 sm:top-[20px]"
              onClick={navBar.backButton}
            >
              <LeftArrow fontSize="small" />
            </span>
          )}
          <p className="text-lg font-semibold max-sm:hidden">{navBar.title}</p>
        </div>
        <div className="hidden md:flex w-1/3 flex-col relative text-on-primary bg-fill-brand-primary hyphens-auto pb-0 pt-[200px] px-9">
          <h2 className="font-semibold text-3xl [word-wrap:break-word]">
            {navBar.title}
          </h2>
          <hr className="my-6 border border-accent" />
          {navBar.link && (
            <p className="text-md hyphens-none [word-wrap:break-word]">
              {navBar.link.description}
            </p>
          )}
          {navBar.link && (
            <Button
              className="text-md flex flex-row justify-start px-0 items-center"
              variant="ghost"
              onClick={navBar.link.onClick}
            >
              {navBar.link.text}
              <RightArrow className="text-accent-500" fontSize="small" />
            </Button>
          )}
          {sideIcon}
        </div>
        <div className="p-0 sm:h-[650px] overflow-y-scroll box-border flex-[0_0_100%] max-w-full md:basis-[calc(8/12*100%)] md:max-w-[calc(8/12*100%)]">
          <div className="ml-[6%] mb-4 w-[88%] text-center">{content}</div>
          <div
            className={cn(
              'md:hidden text-on-primary bg-fill-brand-primary h-[90px] w-full pt-4 text-center mt-auto bottom-0',
              fixedMobileFooter && 'fixed',
            )}
          >
            {navBar.link && (
              <div className="mt-auto">
                <p className="text-md">{navBar.link.description}</p>
                <Button
                  className="font-semibold text-md my-2"
                  variant="link"
                  onClick={navBar.link.onClick}
                >
                  {navBar.link.text}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthDialog
