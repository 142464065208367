import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { ReviewsReferenceV2Storyblok } from '@/types/storyblok-component-types'

export default function ReviewsReferenceV2({
  blok,
}: {
  blok: ReviewsReferenceV2Storyblok
}) {
  return typeof blok.reviews !== 'string' && blok.reviews?.content ? (
    <div {...storyblokEditable(blok)}>
      <StoryblokComponent blok={blok.reviews.content} key={blok._uid} />
    </div>
  ) : null
}
